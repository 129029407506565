import { useState, useEffect } from 'react'
// import getSymbolFromCurrency from 'currency-symbol-map' // delete this package later?
import i18n from '../i18n'
import { Plan } from 'pages/Plans'
import { Trial } from 'pages/Checkout/Form'
import { CouponResponse } from 'hooks/useApi'

export default function usePricing(
  plan: Plan,
  currency: string,
  coupon?: CouponResponse | null,
) {
  const [price, setPrice] = useState<number>(0)
  const [discountedPrice, setDiscountedPrice] = useState<number | null>(null)
  const [trial, setTrial] = useState<Trial | null>(null)
  const [fullyDiscounted, setFullyDiscounted] = useState(false)

  useEffect(() => {
    if (coupon) {
      return
    }

    setDiscountedPrice(null)
    setTrial(null)
    setFullyDiscounted(false)
  }, [coupon])

  useEffect(() => {
    const isRedeemable = coupon?.state === 'redeemable'
    const containsPlanCodes =
      coupon?.plan_codes && coupon?.plan_codes?.length > 0
    const includesPlan = containsPlanCodes
      ? coupon?.plan_codes?.includes(plan.code)
      : true
    const isCurrencyMatchingDiscountType =
      coupon?.discount_type === 'dollars'
        ? coupon.discount_in_cents[currency]
        : true

    if (
      !coupon ||
      !isRedeemable ||
      !includesPlan ||
      !isCurrencyMatchingDiscountType
    ) {
      return
    }

    const calculateDiscount = () => {
      switch (coupon.discount_type) {
        case 'percent':
          const discountRate = coupon.discount_percent!

          if (coupon.discount_percent === 100) {
            if (coupon.duration === 'forever') {
              setFullyDiscounted(true)
            }

            return 0
          } else {
            return price - (discountRate / 100) * price
          }
        case 'dollars':
          const discountInCents = coupon.discount_in_cents[currency]

          if (!discountInCents) {
            return null
          }

          const discount = ['JPY', 'KRW'].includes(currency)
            ? discountInCents
            : discountInCents / 100

          const discountedPrice = Math.max(price - discount, 0)

          if (discountedPrice === 0 && coupon.duration === 'forever') {
            setFullyDiscounted(true)
          }

          return discountedPrice
        case 'free_trial':
          setTrial({
            amount: coupon.free_trial_amount,
            unit: coupon.free_trial_unit,
          })

          return null
        default:
          return null
      }
    }

    setDiscountedPrice(calculateDiscount())
  }, [coupon, plan.code, currency, price])

  useEffect(() => {
    const planCurrencyBased = plan.currencies.find(
      (c) => c.currency === currency,
    )

    setPrice(planCurrencyBased?.unitAmount!)
  }, [plan, currency])

  const formatPrice = (price: number | null) => {
    if (!price && price !== 0) {
      return ''
    }

    return new Intl.NumberFormat(i18n.language, {
      style: 'currency',
      currency,
    }).format(Math.floor(price * 100) / 100)
  }

  const monthly = () => Math.floor((price / plan.intervalLength) * 100) / 100
  const monthlyDiscounted = () =>
    discountedPrice
      ? Math.floor((discountedPrice / plan.intervalLength) * 100) / 100
      : 0

  return {
    trial,
    price,
    totalPrice: formatPrice(price),
    campaignTotalPrice: formatPrice(price * 2),
    monthlyPrice: formatPrice(monthly()),
    campaignMonthlyPrice: formatPrice(monthly() * 2),
    discountedPrice,
    fullyDiscounted,
    totalDiscountedPrice: formatPrice(discountedPrice),
    discounted: !!discountedPrice,
    monthlyDiscountedPrice: discountedPrice
      ? formatPrice(monthlyDiscounted())
      : null,
  }
}
