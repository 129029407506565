import React from 'react'

interface Props {
  selected: number
  units: Unit[]
  onUnitSelected: (arg0: number) => void
}

interface Unit {
  key: number
  unit: string
}

const defaultUnits = [
  { key: 1, unit: 'in' },
  { key: 2, unit: 'cm' },
]

const defaultSelected = 2

export const UnitSwitcher: React.FC<Props> = ({
  units = defaultUnits,
  selected = defaultSelected,
  onUnitSelected,
}) => {
  return (
    <div>
      {units.map((v) => (
        <button
          type="button"
          className={`${
            v.key === selected
              ? 'bg-ar-green text-white'
              : 'bg-white text-ar-green'
          }
                      ${v.key === 1 ? 'rounded-l-lg' : 'rounded-r-lg'}
                      mb-5 w-1/2 border border-ar-green px-8 py-2 text-center
                      font-link text-lg focus:outline-none`}
          key={v.key}
          onClick={() => onUnitSelected(v.key)}>
          {v.unit}
        </button>
      ))}
    </div>
  )
}
