import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  buttonText?: string
  disabled?: boolean
  onClick: () => void
  disabledText?: string
  children?: ReactNode
  id?: string
}

export const ContinueButton: React.FC<Props> = ({
  buttonText,
  disabled = false,
  disabledText,
  onClick,
  children,
  id = 'continue_button',
}) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const translatedButtonText = () => {
    return buttonText || t('button_active')
  }

  const translatedDisabledText = () => {
    return disabledText || t('button_active')
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 border-t border-ar-light-gray border-opacity-10 bg-white py-2">
      <div className="mx-8 my-2 xl:mx-36">
        <button
          id={id}
          type="button"
          onClick={onClick}
          disabled={disabled}
          className={`text-md m-auto block w-full rounded-lg bg-ar-green p-5 text-center font-link text-white
                      focus:outline-none lg:w-5/6 lg:p-4 xl:w-5/12 xxsm:p-3
                      ${disabled ? 'bg-opacity-50' : ''}`}>
          {disabled ? translatedDisabledText() : translatedButtonText()}
        </button>
        {children}
      </div>
    </div>
  )
}
