import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUser } from 'context/UserContext'
import useSnowplow from 'hooks/useSnowplow'
import { ContinueButton } from 'components/ContinueButton'
import { Heading } from 'components/Heading'
import { PreLoadImage } from 'components/PreLoadImage'

interface Props {
  nextRoute: string
}

export const FeedbackTenMinutes: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const navigate = useNavigate()
  const { user } = useUser()
  const preloadBackground =
    user.gender === 'm'
      ? 'bg-height-male-mobile md:bg-height-male'
      : 'bg-height-female'

  const onButtonClick = () => {
    snowplowTrack({
      eventName: 'navigation_click',
      property: 'feedback_tenminutes',
    })

    navigate(nextRoute)
  }

  return (
    <>
      <PreLoadImage background={preloadBackground} />
      <div className="container mx-auto px-4 text-center">
        <div className="my-60">
          <Heading
            textColor="ar-green"
            text={t('feedback_tenminutes.headline')}
          />

          <p className="my-4 font-body text-sm text-ar-dark-gray xl:text-center">
            {t('feedback_tenminutes.subheadline')}
          </p>
        </div>
      </div>
      <ContinueButton onClick={onButtonClick} />
    </>
  )
}
