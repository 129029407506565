import { useTranslation } from 'react-i18next'
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
import { useUser, User } from 'context/UserContext'
import { useAutoRedirect } from 'hooks/useAutoRedirect'
import { RoutePaths } from 'route-paths'
import { FeedbackPersonalize } from 'pages/FeedbackPersonalize'
import { AgingFunnelGender } from 'pages/AgingFunnel/AgingFunnelGender'
import { AgingFunnelFeedbackControl } from 'pages/AgingFunnel/v2/AgingFunnelFeedbackControl'
import { AgingFunnelEnergyLevel } from 'pages/AgingFunnel/v2/AgingFunnelEnergyLevel'
import { AgingFunnelActivityLevel } from 'pages/AgingFunnel/v2/AgingFunnelActivityLevel'
import { AgingFunnelFeedbackActivityLevel } from 'pages/AgingFunnel/v2/AgingFunnelFeedbackActivityLevel'
import { AgingFunnelSittingTime } from 'pages/AgingFunnel/v2/AgingFunnelSittingTime'
import { AgingFunnelPersonalityOptin } from 'pages/AgingFunnel/v2/AgingFunnelPersonalityOptin'
import { AgingFunnelAttitude } from 'pages/AgingFunnel/v2/AgingFunnelAttitude'
import { AgingFunnelSleepTime } from 'pages/AgingFunnel/v2/AgingFunnelSleepTime'
import { AgingFunnelGoalAge } from 'pages/AgingFunnel/v2/AgingFunnelGoalAge'
import { AgingFunnelFeedbackAttitude } from 'pages/AgingFunnel/v2/AgingFunnelFeedbackAttitude'
import { AgingFunnelFeedbackPersonalityTest } from 'pages/AgingFunnel/v2/AgingFunnelFeedbackPersonalityTest'
import { AgingFunnelHeight } from 'pages/AgingFunnel/AgingFunnelHeight'
import { AgingFunnelWeight } from 'pages/AgingFunnel/AgingFunnelWeight'
import { AgingFunnelGoalWeight } from 'pages/AgingFunnel/AgingFunnelGoalWeight'
import { AgingFunnelAge } from 'pages/AgingFunnel/AgingFunnelAge'
import { AgingFunnelSymptoms } from 'pages/AgingFunnel/AgingFunnelSymptoms'
import { AgingFunnelDiet } from 'pages/AgingFunnel/AgingFunnelDiet'
import { AgingFunnelFeedbackGoalAge } from 'pages/AgingFunnel/v2/AgingFunnelFeedbackGoalAge'
import { AgingFunnelFeedbackTenMinutes } from 'pages/AgingFunnel/AgingFunnelFeedbackTenMinutes'
import { AgingFunnelFeedbackExperts } from 'pages/AgingFunnel/AgingFunnelFeedbackExperts'
import { Password } from 'pages/Password'
import { Email } from 'pages/Email'
import { Login } from 'pages/Login'
import { ForgotPassword } from 'pages/ForgotPassword'
import { PasswordReset } from 'pages/PasswordReset'
import { Loading } from 'pages/Loading'
import { Plans } from 'pages/Plans'
import { Checkout } from 'pages/Checkout'
import { ThankYou } from 'pages/ThankYou'
import { MagicLogin } from 'components/MagicLogin'
import { GiftCard } from 'pages/GiftCard'
import { AgingFunnelPersonalityTest } from 'pages/AgingFunnel/v2/AgingFunnelPersonalityTest'
import { CancellationRequest } from 'pages/CancellationRequest'
import { CancellationRequestSuccess } from 'pages/CancellationRequestSuccess'

interface Props {
  locale: string
}

const ProtectedRoute = ({
  user,
  redirectPath = RoutePaths.Login,
}: {
  user: User | undefined
  redirectPath?: string
}) => {
  if (!user?.authToken || !user.email) {
    return <Navigate to={redirectPath} replace />
  }

  return <Outlet />
}

const cancellationRedirectPath = () => {
  const redirectPath = window.location.pathname + window.location.search
  return `${RoutePaths.Login}?redirect_path=${redirectPath}`
}

export const V2AgingFunnelRouter: React.FC<Props> = ({ locale }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { user } = useUser()

  useAutoRedirect()

  return (
    <Routes>
      <Route
        path={RoutePaths.Root}
        element={<Navigate to={RoutePaths.AgingFunnelGender} />}
      />
      <Route
        path={RoutePaths.AgingFunnelGender}
        element={
          <AgingFunnelGender nextRoute={RoutePaths.AgingFunnelSymptoms} />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelSymptoms}
        element={
          <AgingFunnelSymptoms
            nextRoute={RoutePaths.AgingFunnelFeedbackControl}
          />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelFeedbackControl}
        element={
          <AgingFunnelFeedbackControl
            nextRoute={RoutePaths.AgingFunnelAttitude}
          />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelAttitude}
        element={
          <AgingFunnelAttitude
            nextRoute={RoutePaths.AgingFunnelFeedbackAttitude}
          />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelFeedbackAttitude}
        element={
          <AgingFunnelFeedbackAttitude nextRoute={RoutePaths.AgingFunnelAge} />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelAge}
        element={<AgingFunnelAge nextRoute={RoutePaths.AgingFunnelGoalAge} />}
      />
      <Route
        path={RoutePaths.AgingFunnelGoalAge}
        element={
          <AgingFunnelGoalAge
            nextRoute={RoutePaths.AgingFunnelFeedbackGoalAge}
          />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelFeedbackGoalAge}
        element={
          <AgingFunnelFeedbackGoalAge
            nextRoute={RoutePaths.AgingFunnelSleepTime}
          />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelSleepTime}
        element={
          <AgingFunnelSleepTime nextRoute={RoutePaths.AgingFunnelEnergyLevel} />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelEnergyLevel}
        element={
          <AgingFunnelEnergyLevel
            nextRoute={RoutePaths.AgingFunnelActivityLevel}
          />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelActivityLevel}
        element={
          <AgingFunnelActivityLevel
            nextRoute={RoutePaths.AgingFunnelFeedbackActivityLevel}
          />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelFeedbackActivityLevel}
        element={
          <AgingFunnelFeedbackActivityLevel
            nextRoute={RoutePaths.AgingFunnelPersonalityOptin}
          />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelPersonalityOptin}
        element={
          <AgingFunnelPersonalityOptin
            skipRoute={RoutePaths.AgingFunnelSittingTime}
            nextRoute={RoutePaths.AgingFunnelPersonalityOne}
          />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelPersonalityOne}
        element={
          <AgingFunnelPersonalityTest
            text={t('agingFunnel.v2.personality_test.one')}
            nextRoute={RoutePaths.AgingFunnelPersonalityTwo}
          />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelPersonalityTwo}
        element={
          <AgingFunnelPersonalityTest
            text={t('agingFunnel.v2.personality_test.second')}
            nextRoute={RoutePaths.AgingFunnelPersonalityThree}
          />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelPersonalityThree}
        element={
          <AgingFunnelPersonalityTest
            text={t('agingFunnel.v2.personality_test.three')}
            nextRoute={RoutePaths.AgingFunnelPersonalityFourth}
          />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelPersonalityFourth}
        element={
          <AgingFunnelPersonalityTest
            text={t('agingFunnel.v2.personality_test.fourth')}
            nextRoute={RoutePaths.AgingFunnelPersonalityFifth}
          />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelPersonalityFifth}
        element={
          <AgingFunnelPersonalityTest
            text={t('agingFunnel.v2.personality_test.fifth')}
            nextRoute={RoutePaths.AgingFunnelFeedbackPersonalityTest}
          />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelFeedbackPersonalityTest}
        element={
          <AgingFunnelFeedbackPersonalityTest
            nextRoute={RoutePaths.AgingFunnelSittingTime}
          />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelSittingTime}
        element={
          <AgingFunnelSittingTime
            nextRoute={RoutePaths.AgingFunnelFeedbackTenMinutes}
          />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelFeedbackTenMinutes}
        element={
          <AgingFunnelFeedbackTenMinutes
            nextRoute={RoutePaths.AgingFunnelHeight}
          />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelHeight}
        element={<AgingFunnelHeight nextRoute={RoutePaths.AgingFunnelWeight} />}
      />
      <Route
        path={RoutePaths.AgingFunnelWeight}
        element={
          <AgingFunnelWeight nextRoute={RoutePaths.AgingFunnelGoalWeight} />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelGoalWeight}
        element={
          <AgingFunnelGoalWeight nextRoute={RoutePaths.AgingFunnelDiet} />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelDiet}
        element={
          <AgingFunnelDiet nextRoute={RoutePaths.AgingFunnelFeedbackExperts} />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelFeedbackExperts}
        element={<AgingFunnelFeedbackExperts nextRoute={RoutePaths.Password} />}
      />
      <Route
        path={RoutePaths.Password}
        element={<Password nextRoute={RoutePaths.Email} />}
      />
      <Route
        path={RoutePaths.Email}
        element={<Email locale={locale} nextRoute={RoutePaths.Loading} />}
      />
      <Route path={RoutePaths.Login} element={<Login locale={locale} />} />
      <Route
        path={RoutePaths.ForgotPassword}
        element={<ForgotPassword locale={locale} />}
      />
      <Route
        path={RoutePaths.PasswordReset}
        element={<PasswordReset locale={locale} />}
      />
      <Route
        path={RoutePaths.MagicLogin}
        element={<MagicLogin locale={locale} />}
      />
      <Route element={<ProtectedRoute user={user} />}>
        <Route
          path={RoutePaths.Loading}
          element={<Loading locale={locale} />}
        />
        <Route
          path={RoutePaths.Plans}
          element={<Plans locale={locale} nextRoute={RoutePaths.Checkout} />}
        />
        <Route
          path={RoutePaths.Checkout}
          element={
            <Checkout
              locale={locale}
              recurlyPublicKey={process.env.REACT_APP_RECURLY_PUBLIC_KEY || ''}
              nextRoute={RoutePaths.ThankYou}
            />
          }
        />
        <Route
          path={RoutePaths.GiftCard}
          element={<GiftCard locale={locale} nextRoute={RoutePaths.ThankYou} />}
        />
        <Route path={RoutePaths.ThankYou} element={<ThankYou />} />
        <Route
          path={RoutePaths.CancellationRequestSuccess}
          element={<CancellationRequestSuccess />}
        />
      </Route>
      <Route
        element={
          <ProtectedRoute
            user={user}
            redirectPath={cancellationRedirectPath()}
          />
        }>
        <Route
          path={RoutePaths.CancellationRequest}
          element={<CancellationRequest locale={locale} />}
        />
      </Route>
      {/* Currently unused routes */}
      <Route
        path={RoutePaths.FeedbackPersonalize}
        element={<FeedbackPersonalize />}
      />
      {/* For any other route, redirect to root path */}
      <Route path="*" element={<Navigate to={RoutePaths.Root} />} />
    </Routes>
  )
}
