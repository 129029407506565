import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUser, GoalType } from 'context/UserContext'
import useSnowplow from 'hooks/useSnowplow'
import { ContinueButton } from 'components/ContinueButton'
import { GoalButton } from 'components/GoalButton'
import { Heading } from 'components/Heading'
import { SubHeading } from 'components/SubHeading'
import { PreLoadImage } from 'components/PreLoadImage'

interface Props {
  nextRoute: string
}

export const AgingFunnelGoals: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const { user, setUser } = useUser()
  const genderBasedBackground =
    user.gender === 'm'
      ? 'bg-goals-male bg-right'
      : 'bg-goals-female bg-top-left'
  const preloadBackground =
    user.gender === 'm'
      ? 'bg-goals-selection-male'
      : 'bg-goals-selection-female'
  const [canContinue, setCanContinue] = useState(false)
  const [goals, setGoals] = useState([
    {
      key: 1,
      display: t('goals.goal_1'),
      value: 'better-sleep',
      position: '-left-8 top-14',
      selected: false,
      id: 'sleep_better',
    },
    {
      key: 2,
      display: t('goals.goal_2'),
      value: 'lose-weight',
      position: 'left-24',
      selected: false,
      id: 'lose_weight',
    },
    {
      key: 3,
      display: t('goals.goal_3'),
      value: 'tight-toned-body',
      position: '-right-8 top-16',
      selected: false,
      id: 'tight_and_toned',
    },
    {
      key: 4,
      display: t('goals.goal_4'),
      value: 'exercise-effective',
      position: 'left-24 top-36',
      selected: false,
      id: 'exercise_more_effectively',
    },
    {
      key: 5,
      display: t('goals.goal_5'),
      value: 'healthy-habits',
      position: '-left-8 top-52',
      selected: false,
      id: 'build_healthy_habits',
    },
    {
      key: 6,
      display: t('goals.goal_6'),
      value: 'increase-energy-level',
      position: '-right-3 top-60',
      selected: false,
      id: 'more_energy_every_day',
    },
  ])
  const navigate = useNavigate()

  useEffect(() => {
    setCanContinue(goals.filter(({ selected }) => selected).length > 2)
  }, [goals])

  const onGoalClick = (goal: GoalType) => {
    let goalsCopy = [...goals]
    goalsCopy[goalsCopy.findIndex((el) => el.key === goal.key)] = {
      ...goal,
      selected: !goal.selected,
    }
    setGoals(goalsCopy)
  }

  const onContinue = () => {
    const selectedGoals = goals.filter(({ selected }) => selected)

    snowplowTrack({
      eventName: 'navigation_click',
      property: 'goals',
      label: selectedGoals.map(({ id }) => id).join(','),
    })

    setUser({
      ...user,
      goals: selectedGoals.map(({ value }) => value),
      goalTypes: selectedGoals,
    })

    navigate(nextRoute)
  }

  return (
    <>
      <PreLoadImage background={preloadBackground} />
      <div
        className={`z-[-10] h-screen bg-ar-dark-gray bg-cover bg-no-repeat ${genderBasedBackground}`}>
        <div className="mx-auto flex flex-col justify-between px-8 pt-9 lg:w-5/6 xl:w-5/12">
          <div>
            <Heading text={t('goals.headline')} />
            <SubHeading text={t('goals.subheadline')} />
          </div>

          <div className="relative mt-5 sm:flex sm:flex-col sm:space-y-4">
            {goals.map((goal) => (
              <GoalButton
                key={goal.key}
                value={goal.display}
                position={goal.position}
                selected={goal.selected}
                onClick={() => onGoalClick(goal)}
                id={goal.id}
              />
            ))}
          </div>
        </div>
        <ContinueButton
          onClick={onContinue}
          disabled={!canContinue}
          disabledText={t('goals.select_three')}
        />
      </div>
    </>
  )
}
