import React, { ReactNode } from 'react'

interface Props {
  children: ReactNode
  small?: boolean | undefined
}

export const ButtonWrapper: React.FC<Props> = ({ children, small }) => {
  return (
    <div
      className={`${small ? 'bottom-6' : 'bottom-10'}
                  absolute inset-x-0 m-auto w-full px-8 lg:w-5/6 xl:w-1/3`}>
      <div className="grid grid-cols-1 justify-center gap-4">{children}</div>
    </div>
  )
}
