import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUser } from 'context/UserContext'
import useSnowplow from 'hooks/useSnowplow'
import { ContinueButton } from 'components/ContinueButton'
import { RoutePaths } from 'route-paths'

interface Props {
  nextRoute: string
}

export const AgingFunnelFeedbackGoalChoiceV1: React.FC<Props> = ({
  nextRoute,
}) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const { user } = useUser()
  const navigate = useNavigate()

  const onContinue = () => {
    snowplowTrack({
      eventName: 'navigation_click',
      property: 'feedback_goalchoice',
    })
    navigate(nextRoute)
  }

  useEffect(() => {
    if (!user.firstGoal) {
      navigate(RoutePaths.Goals, { replace: true })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (!user.firstGoal) {
    return null
  }

  return (
    <>
      <div className="container mx-auto my-60 px-4 text-center">
        <h1 className="text-center font-title text-2xl font-bold text-ar-green xl:mt-0 xl:text-center">
          {t('agingFunnel.v1.feedback_goal_choice.headline')}{' '}
          <span className="text-ar-green">
            {t(
              `agingFunnel.v1.feedback_goal_choice.${user.firstGoal?.id}.title`,
            )}
          </span>
        </h1>

        <p
          className="my-4 font-body text-sm text-ar-dark-gray xl:text-center"
          dangerouslySetInnerHTML={{
            __html: t(
              `agingFunnel.v1.feedback_goal_choice.${user.firstGoal.id}.body`,
            ),
          }}
        />
      </div>
      <ContinueButton onClick={onContinue} />
    </>
  )
}
