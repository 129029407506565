import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUser } from 'context/UserContext'
import useSnowplow from 'hooks/useSnowplow'
import { ChoiceButton } from 'components/ChoiceButton'
import { ButtonWrapper } from 'components/ButtonWrapper'
import { Heading } from 'components/Heading'

interface Props {
  nextRoute: string
}

export const AgingFunnelActivityLevel: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const navigate = useNavigate()
  const { user, setUser } = useUser()
  const genderBasedBackground =
    user.gender === 'm'
      ? 'bg-agingActivity-male'
      : 'bg-agingActivity-female bg-center'
  const onButtonClick = (activity: string) => {
    snowplowTrack({
      eventName: 'navigation_click',
      property: 'activity_level',
      label: activity,
    })

    setUser({ ...user, activity })
    navigate(nextRoute)
  }

  return (
    <div
      className={`z-[-10] h-screen bg-ar-dark-gray bg-cover bg-center bg-no-repeat ${genderBasedBackground}`}>
      <div className="mx-auto flex flex-col justify-between px-8 pt-9 lg:w-5/6 xl:w-5/12">
        <Heading text={t('agingFunnel.v2.activity_level.headline')} />
        <p className="mt-2 text-left font-body text-sm text-white xl:text-center">
          {t('agingFunnel.v2.activity_level.subheadline')}
        </p>

        <ButtonWrapper>
          <ChoiceButton
            onClick={() => onButtonClick('inactive')}
            value={t('agingFunnel.v2.activity_level.activity_level_1')}
            id="inactive"
          />
          <ChoiceButton
            onClick={() => onButtonClick('active')}
            value={t('agingFunnel.v2.activity_level.activity_level_2')}
            id="active"
          />
          <ChoiceButton
            onClick={() => onButtonClick('very')}
            value={t('agingFunnel.v2.activity_level.activity_level_3')}
            id="very"
          />
        </ButtonWrapper>
      </div>
    </div>
  )
}
