import { useTranslation } from 'react-i18next'
import { useUser } from 'context/UserContext'
import useSnowplow from 'hooks/useSnowplow'
import { ButtonWrapper } from 'components/ButtonWrapper'
import { ChoiceButton } from 'components/ChoiceButton'
import { Header } from 'components/Header'
import { Heading } from 'components/Heading'
import { useNavigate } from 'react-router-dom'
import { PreLoadImage } from 'components/PreLoadImage'

interface Props {
  nextRoute: string
}

export const AgingFunnelGender: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const navigate = useNavigate()
  const { user, setUser } = useUser()
  const { snowplowTrack } = useSnowplow()

  const onButtonClick = (gender: string) => {
    snowplowTrack({
      eventName: 'navigation_click',
      property: 'gender',
      label: gender,
    })
    setUser({ ...user, gender })
    navigate(nextRoute)
  }

  return (
    <>
      {/* This is the only asset we should not use assets path env var */}
      <PreLoadImage src={`./images/feedback_personalize.png`} />
      <div className="z-[-10] h-screen bg-gender bg-cover bg-center bg-no-repeat">
        <Header showLogin color="white" />
        <div className="mx-auto flex flex-col justify-between px-8 pt-9 lg:w-5/6 xl:w-5/12">
          <Heading text={t('gender.headline')} />

          <ButtonWrapper>
            <ChoiceButton
              onClick={() => onButtonClick('f')}
              value={t('gender.gender_1')}
              id="female"
            />
            <ChoiceButton
              onClick={() => onButtonClick('m')}
              value={t('gender.gender_2')}
              id="male"
            />
            <ChoiceButton
              onClick={() => onButtonClick('other')}
              value={t('gender.gender_3')}
              id="non_binary"
            />
          </ButtonWrapper>
        </div>
      </div>
    </>
  )
}
