import { useState, useEffect, useRef, ChangeEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUser } from 'context/UserContext'
import { useKeyPress } from 'hooks/useKeyPress'
import { useAutofocus } from 'hooks/useAutofocus'
import useSnowplow from 'hooks/useSnowplow'
import { ContinueButton } from 'components/ContinueButton'
import { Heading } from 'components/Heading'
import Input from 'components/Input'

interface Props {
  nextRoute: string
}

export const AgingFunnelAge: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const { user, setUser } = useUser()
  const genderBasedBackground =
    user.gender === 'm' ? 'bg-age-male-mobile md:bg-age-male' : 'bg-age-female'
  const navigate = useNavigate()
  const [canContinue, setCanContinue] = useState(false)
  const [year, setYear] = useState('')
  const yearInput = useRef(null)

  const onContinue = () => {
    if (!canContinue) {
      return
    }

    snowplowTrack({
      eventName: 'navigation_click',
      property: 'date_of_birth',
      label: year,
    })

    setUser({ ...user, dateOfBirth: `${year}` })
    setTimeout(() => {
      navigate(nextRoute)
    }, 100)
  }

  const onYearChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value

    if (/(^$|^\d{1,4}$)/.test(input)) {
      setYear(input)
    }
  }

  useKeyPress('ENTER', onContinue)

  useEffect(() => {
    setCanContinue(/^\d{4}$/.test(`${year}`))
  }, [year])

  useAutofocus(yearInput)

  return (
    <>
      <div
        className={`z-[-10] h-screen bg-ar-dark-gray bg-cover bg-center bg-no-repeat ${genderBasedBackground}`}>
        <div className="mx-auto flex h-screen flex-col px-8 pt-9 lg:w-5/6 xl:w-5/12">
          <Heading text={t('age.headline')} />
          <p className="mt-2 text-left font-body text-sm text-white xl:text-center">
            {t('age.subheadline')}
          </p>
          <div className="w-100 z-0 mx-auto mt-5 mb-44 grid h-full w-full content-end sm:mb-28">
            <Input
              placeholder="YYYY"
              value={year}
              id="year_input"
              onChange={onYearChange}
              ref={yearInput}
            />
          </div>
        </div>
      </div>
      <ContinueButton onClick={onContinue} disabled={!canContinue} />
    </>
  )
}
