import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useSnowplow from 'hooks/useSnowplow'
import { ContinueButton } from 'components/ContinueButton'
import { Heading } from 'components/Heading'

interface Props {
  nextRoute: string
}

export const AgingFunnelFeedbackReasonsV1: React.FC<Props> = ({
  nextRoute,
}) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const navigate = useNavigate()

  const onButtonClick = () => {
    snowplowTrack({
      eventName: 'navigation_click',
      property: 'feedback_reasons',
    })

    navigate(nextRoute)
  }

  return (
    <>
      <div className="container mx-auto px-4 text-center">
        <div className="my-60">
          <Heading
            textColor="ar-green"
            text={t('agingFunnel.v1.feedback_reasons.headline')}
          />

          <p className="my-4 font-body text-sm text-ar-dark-gray xl:text-center">
            {t('agingFunnel.v1.feedback_reasons.reason_1')}
            <br />
            {t('agingFunnel.v1.feedback_reasons.reason_2')}
            <br />
            {t('agingFunnel.v1.feedback_reasons.reason_3')}
            <br />
            {t('agingFunnel.v1.feedback_reasons.reason_4')}
            <br />
            {t('agingFunnel.v1.feedback_reasons.reason_5')}
            <br />
            {t('agingFunnel.v1.feedback_reasons.reason_6')}
            <br />
            {t('agingFunnel.v1.feedback_reasons.reason_7')}
          </p>
        </div>
      </div>
      <ContinueButton onClick={onButtonClick} />
    </>
  )
}
