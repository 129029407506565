import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUser } from 'context/UserContext'
import useSnowplow from 'hooks/useSnowplow'
import { ChoiceButton } from 'components/ChoiceButton'
import { ButtonWrapper } from 'components/ButtonWrapper'
import { Heading } from 'components/Heading'

interface Props {
  nextRoute: string
}

export const AgingFunnelEnergyLevel: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const navigate = useNavigate()
  const { user, setUser } = useUser()
  const genderBasedBackground =
    user.gender === 'm' ? 'bg-fitness-level-male' : 'bg-fitness-level-female'
  const onButtonClick = (energy: string) => {
    snowplowTrack({
      eventName: 'navigation_click',
      property: 'energy_level',
      label: energy,
    })

    setUser({ ...user, energy })
    navigate(nextRoute)
  }

  return (
    <div
      className={`z-[-10] h-screen bg-ar-dark-gray bg-cover bg-center bg-no-repeat ${genderBasedBackground}`}>
      <div className="mx-auto flex flex-col justify-between px-8 pt-9 lg:w-5/6 xl:w-5/12">
        <Heading text={t('agingFunnel.v2.energy_level.headline')} />

        <ButtonWrapper>
          <ChoiceButton
            onClick={() => onButtonClick('full')}
            value={t('agingFunnel.v2.energy_level.energy_level_1')}
            id="full"
          />
          <ChoiceButton
            onClick={() => onButtonClick('tired')}
            value={t('agingFunnel.v2.energy_level.energy_level_2')}
            id="tired"
          />
          <ChoiceButton
            onClick={() => onButtonClick('exhausted')}
            value={t('agingFunnel.v2.energy_level.energy_level_3')}
            id="exhausted"
          />
        </ButtonWrapper>
      </div>
    </div>
  )
}
