import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Star } from 'icons/star.svg'

interface Props {}

export const Reviews: React.FC<Props> = () => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const [activeReview, setActiveReview] = useState(0)

  return (
    <>
      <h1 className="mt-12 text-center font-title text-3xl text-ar-dark-gray">
        {t('plans.review_title')}
      </h1>
      <div className="flex items-center justify-center">
        <div className="mt-2 mb-4 flex items-center">
          {[1, 2, 3, 4, 5].map((i) => (
            <Star
              key={i}
              width="20"
              height="20"
              fill="#f5d223"
              className="mt-1"
            />
          ))}
        </div>
      </div>

      <div className="text-center font-body text-sm text-ar-light-gray">
        {t('plans.review_number')}
      </div>
      <div className="m-auto my-4 px-8 text-center font-body text-sm text-ar-dark-gray text-opacity-80 sm:w-5/12">
        <q>{t(`plans.review${activeReview + 1}`)}</q>
        <p className="mt-3 text-center font-title text-sm text-ar-dark-gray">
          {t(`plans.review${activeReview + 1}_name`)}
        </p>
        <div className="m-auto mt-4 flex w-5/6 justify-center gap-1 sm:w-1/3">
          {[0, 1, 2].map((index) => (
            <div
              key={index}
              onClick={() => setActiveReview(index)}
              className={`h-3 w-3 rounded-full border border-ar-green
                            ${activeReview === index ? 'bg-ar-green' : ''}`}
            />
          ))}
        </div>
      </div>
    </>
  )
}
