import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { localeFormat } from 'i18n/localeFormat'
import { SubscriptionObject } from 'hooks/useApi'
import { useUser } from 'context/UserContext'
import useSnowplow from 'hooks/useSnowplow'
import { Modal } from '.'

interface Props {
  subscription: SubscriptionObject
  onClose: () => void
  open: boolean
}

export const SubscriptionInfoModal: React.FC<Props> = ({
  subscription,
  onClose,
  open,
}) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const { user } = useUser()

  const userSubscription = useMemo(
    () => subscription || user.subscription,
    [subscription, user],
  )

  const intervalLength = useMemo(
    () =>
      userSubscription?.interval_in_months ||
      Math.floor(userSubscription?.duration_in_weeks / 4.333),
    [userSubscription],
  )

  const formattedRenewalDate = () => {
    let date = new Date(
      userSubscription?.renewal_date || userSubscription?.expires_date,
    )

    return localeFormat(date, 'PPP')
  }

  return (
    <Modal
      onClose={() => {
        snowplowTrack({
          eventName: 'navigation_click',
          property: 'subscription_info_closed',
        })
        onClose()
      }}
      open={open}>
      <div className="text-md p-10 text-center font-link text-ar-dark-gray">
        <p className="font-link">{t('thank_you.subscription_title')}</p>
        <p className="p-5 text-4xl">❤️</p>
        <p className="font-link">
          {`${t('plans.months', {
            count: intervalLength,
          })} plan`}
        </p>
        <p className="font-body">
          {t('thank_you.renewal', {
            date: formattedRenewalDate(),
          })}
        </p>
        <br />
        <a
          className="font-body text-sm text-ar-green"
          target="_blank"
          rel="noreferrer"
          href="https://asanarebel.zendesk.com/hc/requests/new"
          dangerouslySetInnerHTML={{
            __html: t('thank_you.subscription_help', {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </div>
    </Modal>
  )
}
