import cuid from 'cuid'
import { performTrack } from 'helpers/tracking'
import { User } from 'context/UserContext'

interface TikTokTrackEvent {
  userId: number
  eventName: string
  data?: object
}

interface TikTokPixelTrackEvent {
  eventName: string
  eventID: string
  data?: object
}

export default function useTikTok() {
  const _tikTokPixelScript = () => {
    return (function (w: any, _d: any, t: any) {
      w.TiktokAnalyticsObject = t
      var ttq = (w[t] = w[t] || [])

      ttq.methods = [
        'page',
        'track',
        'identify',
        'instances',
        'debug',
        'on',
        'off',
        'once',
        'ready',
        'alias',
        'group',
        'enableCookie',
        'disableCookie',
      ]

      ttq.setAndDefer = function (t: any, e: any) {
        t[e] = function () {
          t.push([e].concat(Array.prototype.slice.call(arguments, 0)))
        }
      }
      for (var i = 0; i < ttq.methods.length; i++) {
        ttq.setAndDefer(ttq, ttq.methods[i])
      }

      ttq.instance = function (t: any) {
        for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
          ttq.setAndDefer(e, ttq.methods[n])
        return e
      }

      ttq.load = function (e: any, n: any) {
        var i = 'https://analytics.tiktok.com/i18n/pixel/events.js'
        ttq._i = ttq._i || {}
        ttq._i[e] = []
        ttq._i[e]._u = i
        ttq._t = ttq._t || {}
        ttq._t[e] = +new Date()
        ttq._o = ttq._o || {}
        ttq._o[e] = n || {}
        var o = document.createElement('script')
        o.type = 'text/javascript'
        o.async = !0
        o.src = i + '?sdkid=' + e + '&lib=' + t
        var a = document.getElementsByTagName('script')[0]
        a.parentNode!.insertBefore(o, a)
      }

      ttq.load(process.env.REACT_APP_TIK_TOK_PIXEL_ID)
    })(window, document, 'ttq')
  }

  const _tikTokIdentify = (user?: User) => {
    if (typeof ttq === 'undefined') {
      return
    }

    ttq.identify({
      email: user?.email,
      external_id: user?.id,
    })
  }

  const tikTokInit = (user?: User) => {
    _tikTokPixelScript()
    _tikTokIdentify(user)
  }

  const tikTokPageView = (user: User) => {
    if (typeof ttq === 'undefined') {
      return
    }

    const eventID = cuid()
    const eventSourceUrl = window.location.origin + window.location.pathname

    performTrack({
      platform: 'tiktok',
      eventName: 'Pageview',
      payload: {
        event_id: eventID,
        user_id: user.id,
        content_id: eventSourceUrl,
      },
    })

    ttq.page({ event_id: eventID, content_id: eventSourceUrl })
  }

  const tikTokTrack = ({ userId, eventName, data }: TikTokTrackEvent) => {
    if (typeof ttq === 'undefined') {
      return
    }

    const eventID = cuid()
    const eventSourceUrl = window.location.origin + window.location.pathname

    performTrack({
      platform: 'tiktok',
      eventName: eventName,
      payload: {
        event_id: eventID,
        user_id: userId,
        content_id: eventSourceUrl,
        ...data,
      },
    })

    tikTokPixelTrack({
      eventName,
      eventID,
      data: { content_id: eventSourceUrl, ...data },
    })
  }

  const tikTokPixelTrack = ({
    eventName,
    data,
    eventID,
  }: TikTokPixelTrackEvent) => {
    ttq.track(eventName, { ...data }, { event_id: eventID })
  }

  return {
    tikTokInit,
    tikTokPageView,
    tikTokTrack,
    tikTokPixelTrack,
  }
}
