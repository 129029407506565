import React from 'react'

interface Props {
  id?: string
  value: string
  position: string
  selected: boolean
  onClick: () => void
}

export const GoalButton: React.FC<Props> = ({
  value,
  position,
  selected,
  onClick,
  id,
}) => {
  return (
    <div
      className={`absolute ${position} sm:relative sm:inset-0 sm:-right-0 sm:top-0 sm:left-0`}>
      <button
        type="button"
        onClick={onClick}
        id={id}
        className={`h-32 w-32 rounded-full p-2 font-body text-sm
                    focus:outline-none sm:h-auto sm:w-full sm:rounded-lg sm:p-5
                    sm:font-title sm:text-base
                    ${
                      selected
                        ? 'bg-ar-green text-white'
                        : 'bg-white text-ar-light-gray hover:bg-ar-white sm:text-ar-dark-gray'
                    } `}>
        {value}
      </button>
    </div>
  )
}
