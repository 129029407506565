import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {}

export const Benefits: React.FC<Props> = () => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  return (
    <div className="mt-12 flex flex-col gap-5 text-center font-body text-sm text-ar-dark-gray text-opacity-80">
      <p>{t('plans.benefits_loosing_weight')}</p>
      <p>{t('plans.benefits_work_anywhere')}</p>
      <p>{t('plans.benefits_daily_challenge')}</p>
      <p>{t('plans.benefits_5_min')}</p>
      <p>{t('plans.benefits_clarity')}</p>
    </div>
  )
}
