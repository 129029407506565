import { useTranslation } from 'react-i18next'
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
import { useUser, User } from 'context/UserContext'
import { useAutoRedirect } from 'hooks/useAutoRedirect'
import { RoutePaths } from 'route-paths'
import { FeedbackPersonalize } from 'pages/FeedbackPersonalize'
import { AgingFunnelGender } from 'pages/AgingFunnel/AgingFunnelGender'
import { AgingFunnelGoalsV1 } from 'pages/AgingFunnel/v1/AgingFunnelGoalsV1'
import { AgingFunnelAttitude } from 'pages/AgingFunnel/v2/AgingFunnelAttitude'
import { AgingFunnelFeedbackAttitude } from 'pages/AgingFunnel/v2/AgingFunnelFeedbackAttitude'
import { AgingFunnelFeedbackControl } from 'pages/AgingFunnel/v2/AgingFunnelFeedbackControl'
import { AgingFunnelFeedbackReasonsV1 } from 'pages/AgingFunnel/v1/AgingFunnelFeedbackReasonsV1'
import { AgingFunnelGoalSelection } from 'pages/AgingFunnel/AgingFunnelGoalSelection'
import { AgingFunnelFeedbackGoalset } from 'pages/AgingFunnel/AgingFunnelFeedbackGoalset'
import { AgingFunnelGoalAge } from 'pages/AgingFunnel/v2/AgingFunnelGoalAge'
import { AgingFunnelFeedbackGoalAge } from 'pages/AgingFunnel/v2/AgingFunnelFeedbackGoalAge'
import { AgingFunnelHeight } from 'pages/AgingFunnel/AgingFunnelHeight'
import { AgingFunnelWeight } from 'pages/AgingFunnel/AgingFunnelWeight'
import { AgingFunnelGoalWeight } from 'pages/AgingFunnel/AgingFunnelGoalWeight'
import { AgingFunnelFeedbackGoalWeight } from 'pages/AgingFunnel/AgingFunnelFeedbackGoalWeight'
import { AgingFunnelAge } from 'pages/AgingFunnel/AgingFunnelAge'
import { AgingFunnelDiet } from 'pages/AgingFunnel/AgingFunnelDiet'
import { AgingFunnelActivity } from 'pages/AgingFunnel/AgingFunnelActivity'
import { AgingFunnelFeedbackTenMinutes } from 'pages/AgingFunnel/AgingFunnelFeedbackTenMinutes'
import { AgingFunnelDietChoice } from 'pages/AgingFunnel/AgingFunnelDietChoice'
import { AgingFunnelFeedbackExperts } from 'pages/AgingFunnel/AgingFunnelFeedbackExperts'
import { Password } from 'pages/Password'
import { Email } from 'pages/Email'
import { Login } from 'pages/Login'
import { ForgotPassword } from 'pages/ForgotPassword'
import { PasswordReset } from 'pages/PasswordReset'
import { Loading } from 'pages/Loading'
import { Plans } from 'pages/Plans'
import { Checkout } from 'pages/Checkout'
import { ThankYou } from 'pages/ThankYou'
import { MagicLogin } from 'components/MagicLogin'
import { GiftCard } from 'pages/GiftCard'
import { AgingFunnelStatementV1 } from 'pages/AgingFunnel/v1/AgingFunnelStatementV1'
import { CancellationRequest } from 'pages/CancellationRequest'
import { CancellationRequestSuccess } from 'pages/CancellationRequestSuccess'

interface Props {
  locale: string
}

const ProtectedRoute = ({
  user,
  redirectPath = RoutePaths.Login,
}: {
  user: User | undefined
  redirectPath?: string
}) => {
  if (!user?.authToken || !user.email) {
    return <Navigate to={redirectPath} replace />
  }

  return <Outlet />
}

const cancellationRedirectPath = () => {
  const redirectPath = window.location.pathname + window.location.search
  return `${RoutePaths.Login}?redirect_path=${redirectPath}`
}

export const V3AgingFunnelRouter: React.FC<Props> = ({ locale }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { user } = useUser()

  useAutoRedirect()

  return (
    <Routes>
      <Route
        path={RoutePaths.Root}
        element={<Navigate to={RoutePaths.AgingFunnelGender} />}
      />
      <Route
        path={RoutePaths.AgingFunnelGender}
        element={<AgingFunnelGender nextRoute={RoutePaths.AgingFunnelGoals} />}
      />
      <Route
        path={RoutePaths.AgingFunnelGoals}
        element={
          <AgingFunnelGoalsV1 nextRoute={RoutePaths.AgingFunnelGoalSelection} />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelGoalSelection}
        element={
          <AgingFunnelGoalSelection
            nextRoute={RoutePaths.AgingFunnelFeedbackGoalset}
          />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelFeedbackGoalset}
        element={
          <AgingFunnelFeedbackGoalset
            nextRoute={RoutePaths.AgingFunnelFeedbackControl}
          />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelFeedbackControl}
        element={
          <AgingFunnelFeedbackControl
            nextRoute={RoutePaths.AgingFunnelAttitude}
          />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelAttitude}
        element={
          <AgingFunnelAttitude
            nextRoute={RoutePaths.AgingFunnelFeedbackAttitude}
          />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelFeedbackAttitude}
        element={
          <AgingFunnelFeedbackAttitude
            nextRoute={RoutePaths.AgingFunnelStatementOneV1}
          />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelStatementOneV1}
        element={
          <AgingFunnelStatementV1
            text={t('agingFunnel.v1.statements.one')}
            nextRoute={RoutePaths.AgingFunnelStatementTwoV1}
          />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelStatementTwoV1}
        element={
          <AgingFunnelStatementV1
            text={t('agingFunnel.v1.statements.second')}
            nextRoute={RoutePaths.AgingFunnelStatementThreeV1}
          />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelStatementThreeV1}
        element={
          <AgingFunnelStatementV1
            text={t('agingFunnel.v1.statements.three')}
            nextRoute={RoutePaths.AgingFunnelFeedbackTenMinutes}
          />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelFeedbackTenMinutes}
        element={
          <AgingFunnelFeedbackTenMinutes
            nextRoute={RoutePaths.AgingFunnelAge}
          />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelAge}
        element={<AgingFunnelAge nextRoute={RoutePaths.AgingFunnelGoalAge} />}
      />
      <Route
        path={RoutePaths.AgingFunnelGoalAge}
        element={
          <AgingFunnelGoalAge
            nextRoute={RoutePaths.AgingFunnelFeedbackGoalAge}
          />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelFeedbackGoalAge}
        element={
          <AgingFunnelFeedbackGoalAge
            nextRoute={RoutePaths.AgingFunnelActivity}
          />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelActivity}
        element={<AgingFunnelActivity nextRoute={RoutePaths.AgingFunnelDiet} />}
      />
      <Route
        path={RoutePaths.AgingFunnelDiet}
        element={<AgingFunnelDiet nextRoute={RoutePaths.AgingFunnelHeight} />}
      />
      <Route
        path={RoutePaths.AgingFunnelHeight}
        element={<AgingFunnelHeight nextRoute={RoutePaths.AgingFunnelWeight} />}
      />
      <Route
        path={RoutePaths.AgingFunnelWeight}
        element={
          <AgingFunnelWeight nextRoute={RoutePaths.AgingFunnelGoalWeight} />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelGoalWeight}
        element={
          <AgingFunnelGoalWeight
            nextRoute={RoutePaths.AgingFunnelFeedbackGoalWeight}
          />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelFeedbackGoalWeight}
        element={
          <AgingFunnelFeedbackGoalWeight
            nextRoute={RoutePaths.AgingFunnelDietChoice}
          />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelDietChoice}
        element={
          <AgingFunnelDietChoice
            nextRoute={RoutePaths.AgingFunnelFeedbackExperts}
          />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelFeedbackExperts}
        element={
          <AgingFunnelFeedbackExperts
            nextRoute={RoutePaths.AgingFunnelFeedbackReasonsV1}
          />
        }
      />
      <Route
        path={RoutePaths.AgingFunnelFeedbackReasonsV1}
        element={
          <AgingFunnelFeedbackReasonsV1 nextRoute={RoutePaths.Password} />
        }
      />
      <Route
        path={RoutePaths.Password}
        element={<Password nextRoute={RoutePaths.Email} />}
      />
      <Route
        path={RoutePaths.Email}
        element={<Email locale={locale} nextRoute={RoutePaths.Loading} />}
      />
      <Route path={RoutePaths.Login} element={<Login locale={locale} />} />
      <Route
        path={RoutePaths.ForgotPassword}
        element={<ForgotPassword locale={locale} />}
      />
      <Route
        path={RoutePaths.PasswordReset}
        element={<PasswordReset locale={locale} />}
      />
      <Route
        path={RoutePaths.MagicLogin}
        element={<MagicLogin locale={locale} />}
      />
      <Route element={<ProtectedRoute user={user} />}>
        <Route
          path={RoutePaths.Loading}
          element={<Loading locale={locale} />}
        />
        <Route
          path={RoutePaths.Plans}
          element={<Plans locale={locale} nextRoute={RoutePaths.Checkout} />}
        />
        <Route
          path={RoutePaths.Checkout}
          element={
            <Checkout
              locale={locale}
              recurlyPublicKey={process.env.REACT_APP_RECURLY_PUBLIC_KEY || ''}
              nextRoute={RoutePaths.ThankYou}
            />
          }
        />
        <Route
          path={RoutePaths.GiftCard}
          element={<GiftCard locale={locale} nextRoute={RoutePaths.ThankYou} />}
        />
        <Route path={RoutePaths.ThankYou} element={<ThankYou />} />
        <Route
          path={RoutePaths.CancellationRequestSuccess}
          element={<CancellationRequestSuccess />}
        />
      </Route>
      <Route
        element={
          <ProtectedRoute
            user={user}
            redirectPath={cancellationRedirectPath()}
          />
        }>
        <Route
          path={RoutePaths.CancellationRequest}
          element={<CancellationRequest locale={locale} />}
        />
      </Route>
      {/* Currently unused routes */}
      <Route
        path={RoutePaths.FeedbackPersonalize}
        element={<FeedbackPersonalize />}
      />
      {/* For any other route, redirect to root path */}
      <Route path="*" element={<Navigate to={RoutePaths.Root} />} />
    </Routes>
  )
}
