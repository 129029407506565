import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUser, EmotionType } from 'context/UserContext'
import useSnowplow from 'hooks/useSnowplow'
import { ContinueButton } from 'components/ContinueButton'
import { EmotionButton } from 'components/EmotionButton'
import { Heading } from 'components/Heading'
import { PreLoadImage } from 'components/PreLoadImage'

interface Props {
  nextRoute: string
}

export const AgingFunnelAttitude: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const { user, setUser } = useUser()

  const preloadBackground =
    user.gender === 'm'
      ? 'bg-goals-selection-male'
      : 'bg-goals-selection-female'
  const [canContinue, setCanContinue] = useState(false)
  const [emotions, setEmotions] = useState([
    {
      key: 1,
      display: t('agingFunnel.v2.attitude.emotion_1'),
      value: 'accepting',
      selected: false,
      id: 'accepting',
      positive_emotion: 1,
    },
    {
      key: 2,
      display: t('agingFunnel.v2.attitude.emotion_2'),
      value: 'vulnerable',
      selected: false,
      id: 'vulnerable',
      positive_emotion: 0,
    },
    {
      key: 3,
      display: t('agingFunnel.v2.attitude.emotion_3'),
      value: 'unprepared',
      selected: false,
      id: 'unprepared',
      positive_emotion: 0,
    },
    {
      key: 4,
      display: t('agingFunnel.v2.attitude.emotion_4'),
      value: 'uncomfortable',
      selected: false,
      id: 'uncomfortable',
      positive_emotion: 0,
    },
    {
      key: 5,
      display: t('agingFunnel.v2.attitude.emotion_5'),
      value: 'optimistic',
      selected: false,
      id: 'optimistic',
      positive_emotion: 1,
    },
    {
      key: 6,
      display: t('agingFunnel.v2.attitude.emotion_6'),
      value: 'adaptable',
      selected: false,
      id: 'adaptable',
      positive_emotion: 1,
    },
    {
      key: 7,
      display: t('agingFunnel.v2.attitude.emotion_7'),
      value: 'pessimistic',
      selected: false,
      id: 'pessimistic',
      positive_emotion: 0,
    },
    {
      key: 8,
      display: t('agingFunnel.v2.attitude.emotion_8'),
      value: 'enthusiastic',
      selected: false,
      id: 'enthusiastic',
      positive_emotion: 1,
    },
    {
      key: 9,
      display: t('agingFunnel.v2.attitude.emotion_9'),
      value: 'empowering',
      selected: false,
      id: 'empowering',
      positive_emotion: 1,
    },
  ])
  const navigate = useNavigate()

  useEffect(() => {
    setCanContinue(emotions.filter(({ selected }) => selected).length > 0)
  }, [emotions])

  const onGoalClick = (emotion: EmotionType) => {
    let emotionsCopy = [...emotions]
    emotionsCopy[emotionsCopy.findIndex((el) => el.key === emotion.key)] = {
      ...emotion,
      selected: !emotion.selected,
    }
    setEmotions(emotionsCopy)
  }

  const onContinue = () => {
    const selectedEmotions = emotions.filter(({ selected }) => selected)

    snowplowTrack({
      eventName: 'navigation_click',
      property: 'emotions',
      label: selectedEmotions.map(({ id }) => id).join(','),
    })

    setUser({
      ...user,
      emotions: selectedEmotions.map(({ value }) => value),
      emotionTypes: selectedEmotions,
      emotionColor: selectedEmotions.map(
        ({ positive_emotion }) => positive_emotion,
      ),
    })

    navigate(nextRoute)
  }

  return (
    <>
      <PreLoadImage background={preloadBackground} />
      <div className={`z-[-10] h-screen text-ar-dark-gray`}>
        <div className="mx-auto flex flex-col justify-between px-8 pt-9 lg:w-5/6 xl:w-5/12">
          <div>
            <Heading
              textColor="ar-dark-gray"
              text={t('agingFunnel.v2.attitude.headline')}
            />
            <p className="mt-2 text-sm xl:text-center">
              {t('agingFunnel.v2.attitude.subheadline')}
            </p>
          </div>

          <div className="relative mt-5 space-y-4">
            {emotions.map((emotion) => (
              <EmotionButton
                key={emotion.key}
                value={emotion.display}
                selected={emotion.selected}
                onClick={() => onGoalClick(emotion)}
                id={emotion.id}
              />
            ))}
          </div>
        </div>
        <ContinueButton onClick={onContinue} disabled={!canContinue} />
      </div>
    </>
  )
}
