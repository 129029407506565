import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useSnowplow from 'hooks/useSnowplow'
import { Confetti } from './Confetti'
import { ContinueButton } from 'components/ContinueButton'
import { Heading } from 'components/Heading'
import { PreLoadImage } from 'components/PreLoadImage'

interface Props {
  nextRoute: string
}

export const FeedbackGoalset: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const navigate = useNavigate()

  const onButtonClick = () => {
    snowplowTrack({
      eventName: 'navigation_click',
      property: 'feedback_goalset',
    })
    navigate(nextRoute)
  }

  return (
    <>
      <PreLoadImage src="/images/feedback_goalchoice.png" />
      <div className="container mx-auto px-4 text-center">
        <div className="my-60">
          <div className="fixed left-1/2">
            <Confetti active />
          </div>
          <Heading textColor="ar-green" text={t('feedback_goalset.headline')} />
          <p className="my-4 font-body text-sm text-ar-dark-gray xl:text-center">
            {t('feedback_goalset.subheadline')}
          </p>
        </div>
      </div>
      <ContinueButton onClick={onButtonClick} />
    </>
  )
}
