import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUser } from 'context/UserContext'
import useSnowplow from 'hooks/useSnowplow'
import { ContinueButton } from 'components/ContinueButton'
import { Heading } from 'components/Heading'
import { RoutePaths } from 'route-paths'

interface Props {
  nextRoute: string
}

export const AgingFunnelFeedbackAttitude: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const { user } = useUser()
  const navigate = useNavigate()

  const onContinue = () => {
    snowplowTrack({
      eventName: 'navigation_click',
      property: 'feedback_goalchoice',
    })
    navigate(nextRoute)
  }

  useEffect(() => {
    if (!user.emotionColor) {
      navigate(RoutePaths.Goals, { replace: true })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (!user.emotionColor) {
    return null
  }

  const positiveEmotionsCount = user.emotionColor?.filter(
    (emotion) => emotion === 1,
  ).length

  const negativeEmotionsCount = user.emotionColor?.filter(
    (emotion) => emotion === 0,
  ).length

  const subheadlineTranslatedEmotions = () => {
    return positiveEmotionsCount > negativeEmotionsCount
      ? t('agingFunnel.v2.feedback_attitude.subheadline_positive')
      : t('agingFunnel.v2.feedback_attitude.subheadline_negative')
  }

  const headlineTranslatedEmotions = () => {
    return positiveEmotionsCount > negativeEmotionsCount
      ? t('agingFunnel.v2.feedback_attitude.headline_positive')
      : t('agingFunnel.v2.feedback_attitude.headline_negative')
  }

  return (
    <>
      <div className="container mx-auto px-4 text-center">
        <div className="my-60">
          <Heading textColor="ar-green" text={headlineTranslatedEmotions()} />

          <p className="my-4 font-body text-sm text-ar-dark-gray xl:text-center">
            {subheadlineTranslatedEmotions()}
          </p>
        </div>
      </div>
      <ContinueButton onClick={onContinue} />
    </>
  )
}
