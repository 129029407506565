import cuid from 'cuid'
import { performTrack } from 'helpers/tracking'

export default function useGoogleTag() {
  const googleTrackInitiateCheckout = (
    value: number,
    currency: string,
    userID: number,
  ) => {
    const eventID = cuid()

    gtag('event', 'conversion', {
      send_to: 'AW-767545990/b_seCJ-Yg9kDEIal_-0C',
      value,
      currency,
      transaction_id: eventID,
    })

    performTrack({
      platform: 'google',
      eventName: 'InitiateCheckout',
      payload: {
        currency,
        value,
        event_id: eventID,
        user_id: userID,
      },
    })
  }

  const googleTrackCompleteRegistration = (userID: number) => {
    const eventID = cuid()

    gtag('event', 'conversion', {
      send_to: 'AW-767545990/TMm9COmx55MBEIal_-0C',
      transaction_id: eventID,
    })

    performTrack({
      platform: 'google',
      eventName: 'CompleteRegistration',
      payload: {
        event_id: eventID,
        user_id: userID,
      },
    })
  }

  return {
    googleTrackCompleteRegistration,
    googleTrackInitiateCheckout,
  }
}
