import { useState, useEffect, useRef, ChangeEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUser } from 'context/UserContext'
import { useKeyPress } from 'hooks/useKeyPress'
import { useAutofocus } from 'hooks/useAutofocus'
import useSnowplow from 'hooks/useSnowplow'
import { ContinueButton } from 'components/ContinueButton'
import { Heading } from 'components/Heading'
import Input from 'components/Input'

interface Props {
  nextRoute: string
}

export const AgingFunnelSittingTime: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const { user, setUser } = useUser()
  const navigate = useNavigate()
  const [canContinue, setCanContinue] = useState(false)
  const [sitting, setTime] = useState('')
  const sittingInput = useRef(null)

  const onContinue = () => {
    if (!canContinue) {
      return
    }

    snowplowTrack({
      eventName: 'navigation_click',
      property: 'sitting_time',
      label: sitting,
    })

    setUser({ ...user, sittingTime: `${sitting}` })
    setTimeout(() => {
      navigate(nextRoute)
    }, 100)
  }

  const onSittingChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value

    if (/(^$|^\d{1,2}$)/.test(input)) {
      setTime(input)
    }
  }

  useKeyPress('ENTER', onContinue)

  useEffect(() => {
    setCanContinue(/^\d{1,2}$/.test(`${sitting}`))
  }, [sitting])

  useAutofocus(sittingInput)

  return (
    <>
      <div className="z-[-10] h-screen bg-ar-dark-gray bg-sitting bg-cover bg-center bg-no-repeat">
        <div className="mx-auto flex h-screen flex-col px-8 pt-9 lg:w-5/6 xl:w-5/12">
          <Heading text={t('agingFunnel.v2.sitting_time.headline')} />
          <p className="mt-2 text-left font-body text-sm text-white xl:text-center">
            {t('agingFunnel.v2.sitting_time.subheadline')}
          </p>
          <div className="w-100 z-0 mx-auto mt-5 mb-44 grid h-full w-full content-end sm:mb-28">
            <Input
              placeholder={t('agingFunnel.v2.sitting_time.placeholder')}
              value={sitting}
              id="sitting_input"
              onChange={onSittingChange}
              ref={sittingInput}
            />
          </div>
        </div>
      </div>
      <ContinueButton onClick={onContinue} disabled={!canContinue} />
    </>
  )
}
