import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useSnowplow from 'hooks/useSnowplow'
import { ButtonWrapper } from 'components/ButtonWrapper'
import { DesicionButton } from 'components/DesicionButton'
import { Heading } from 'components/Heading'

interface Props {
  nextRoute: string
  skipRoute: string
}

export const AgingFunnelPersonalityOptin: React.FC<Props> = ({
  nextRoute,
  skipRoute,
}) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const navigate = useNavigate()

  const onButtonClick = () => {
    snowplowTrack({
      eventName: 'navigation_click',
      property: 'personality_optin',
    })

    navigate(nextRoute)
  }

  const onSkipClick = () => {
    snowplowTrack({
      eventName: 'navigation_click',
      property: 'personality_optin',
    })

    navigate(skipRoute)
  }

  return (
    <>
      <div className="container mx-auto my-8 px-4 text-center">
        <Heading
          textColor="ar-dark-gray"
          text={t('agingFunnel.v2.personality_optin.headline')}
        />

        <p className="my-4 font-body text-sm text-ar-dark-gray xl:text-center">
          {t('agingFunnel.v2.personality_optin.subheadline')}
        </p>

        <p className="my-4 font-body text-sm text-ar-dark-gray xl:text-center">
          {t('agingFunnel.v2.personality_optin.start_test')}
        </p>

        <ButtonWrapper small>
          <DesicionButton
            buttonText={t(
              'agingFunnel.v2.personality_optin.continue_button_yes',
            )}
            onClick={onButtonClick}
          />
          <DesicionButton
            buttonText={t(
              'agingFunnel.v2.personality_optin.continue_button_no',
            )}
            onClick={onSkipClick}
          />
        </ButtonWrapper>
      </div>
    </>
  )
}
