import {
  useEffect,
  useMemo,
  forwardRef,
  useImperativeHandle,
  ReactNode,
} from 'react'
import { useTranslation } from 'react-i18next'
import { UseRecurlyInstance } from '@recurly/react-recurly'
import paypalImg from './paypal.png'
import useApi, { SubscriptionObject } from 'hooks/useApi'
import useFacebookCookies from 'hooks/useFacebookCookies'
import * as Sentry from '@sentry/react'

interface Props {
  children?: ReactNode
  recurly: UseRecurlyInstance
  fullyDiscounted: boolean
  setPaymentOption: (arg0: string) => void
  paymentOption: string
  braintreeClientToken: string
  handleError: (arg0: Error | string) => void
  setLoading: (arg0: boolean) => void
  couponCode?: string | null
  trackSuccessfulSubscription: (arg0: SubscriptionObject) => void
  userCountry?: string
  authToken: string
  currency: string
  planCode: string
  locale: string
}

type Ref = ReactNode | null

export const PayPal = forwardRef<Ref, Props>(
  (
    {
      recurly,
      fullyDiscounted,
      setPaymentOption,
      paymentOption,
      braintreeClientToken,
      handleError,
      setLoading,
      trackSuccessfulSubscription,
      userCountry,
      authToken,
      currency,
      planCode,
      locale,
      couponCode,
    },
    ref,
  ) => {
    useImperativeHandle(ref, () => ({
      submitPayment() {
        handlePaypal()
      },
    }))

    const { t } = useTranslation('translation', {
      useSuspense: false,
    })

    const { createSubscription } = useApi(locale)

    const { fbp, fbc } = useFacebookCookies()

    const paypal = recurly.PayPal({
      braintree: {
        clientAuthorization: braintreeClientToken,
      },
      display: {
        displayName: 'Asana Rebel Subscription',
      },
    })

    const handlePaypal = () => {
      paypal.start()
    }

    const createBackendSubscription = useMemo(
      () =>
        async ({ paypalToken = '' }) => {
          try {
            const { subscription, errors } = await createSubscription({
              authToken,
              paypalToken,
              currency,
              planCode,
              country: userCountry,
              fbp,
              fbc,
              ...(couponCode && { couponCode }),
            })

            if (errors) {
              handleError(errors.message || t('errors.unexpected_error'))
            } else {
              trackSuccessfulSubscription(subscription)
            }
          } catch (error) {
            Sentry.captureException(error)
            handleError(t('errors.unexpected_error'))
          }
        },
      [
        fbp,
        fbc,
        currency,
        planCode,
        userCountry,
        createSubscription,
        t,
        authToken,
        handleError,
        trackSuccessfulSubscription,
        couponCode,
      ],
    )

    const onClick = () => {
      fullyDiscounted ? setPaymentOption('cc') : setPaymentOption('paypal')
    }

    useEffect(() => {
      if (!paypal) {
        return
      }

      paypal.on('error', (error) => {
        handleError(error)
      })

      paypal.on('cancel', () => {
        setLoading(false)
      })

      paypal.on('token', (token) => {
        createBackendSubscription({
          paypalToken: token.id,
        })
      })
    }, [paypal, createBackendSubscription, handleError, setLoading])

    return (
      <div className="rounded-lg p-6 shadow-lg ring-1 ring-ar-white">
        <div className="flex cursor-pointer justify-between" onClick={onClick}>
          <h3 className="text-md font-title xl:text-sm">
            {t('checkout.paypal')}
          </h3>
          <img src={paypalImg} alt="PayPal" height="24" width="34" />
        </div>
        <p
          className={`mt-3 font-body text-sm
                          ${paymentOption === 'paypal' ? 'block' : 'hidden'}`}>
          {t('checkout.paypal_description')}
        </p>
      </div>
    )
  },
)
