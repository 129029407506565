import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useUser } from 'context/UserContext'
import { SubscriptionInfoModal } from 'components/Modal/SubscriptionInfo'
import { Heading } from 'components/Heading'
import { ContinueButton } from 'components/ContinueButton'
import { ReactComponent as Logo } from 'icons/logo.svg'
import { useNavigate } from 'react-router-dom'
import useSnowplow from 'hooks/useSnowplow'
import { RoutePaths } from 'route-paths'

const ADJUST_BASEURL = 'https://app.adjust.com'
const ADJUST_PATH = '/d0ud0kd'
const ADJUST_LINK = `${ADJUST_BASEURL}${ADJUST_PATH}`

interface Props {}

export const ThankYou: React.FC<Props> = () => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const { user } = useUser()
  const navigate = useNavigate()

  const [showModal, setShowModal] = useState(false)

  const onContinue = () => {
    snowplowTrack({
      eventName: 'navigation_click',
      property: 'download_the_app',
    })

    window.open(ADJUST_LINK, '_blank')
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!user.subscription) {
      navigate(RoutePaths.Checkout, { replace: true })
    }
  }, [user.subscription, navigate])

  if (!user.subscription) {
    return null
  }

  return (
    <>
      {showModal && (
        <SubscriptionInfoModal
          onClose={() => setShowModal(false)}
          open={showModal}
          subscription={user.subscription}
        />
      )}
      <header className="m-auto grid grid-cols-2 px-8 pt-9 lg:w-5/6">
        <Logo height="30" width="35" fill="black" />
        <span
          onClick={() => {
            snowplowTrack({
              eventName: 'navigation_click',
              property: 'subscription_info',
            })
            setShowModal(true)
          }}
          className="z-10 cursor-pointer text-right font-link text-sm text-ar-dark-gray">
          {t('thank_you.subscription_info')}
        </span>
      </header>
      <div className="mx-auto flex flex-col justify-start px-8 pt-9 lg:w-5/6 xl:w-5/12">
        <Heading text={t('thank_you.welcome')} textColor="black" centered />
        <div className="text-ar-dark-gray-text-sm mt-8 mb-28 text-center font-body">
          <p
            dangerouslySetInnerHTML={{
              __html: t('thank_you.thank_you', {
                interpolation: { escapeValue: false },
              }),
            }}
          />
          <br />

          <p>{t('thank_you.instructions')}</p>
          <br />

          <p
            dangerouslySetInnerHTML={{
              __html: t('thank_you.step_one', {
                link: ADJUST_LINK,
                interpolation: {
                  escapeValue: false,
                },
              }),
            }}
          />
          <br />

          <p
            dangerouslySetInnerHTML={{
              __html: t('thank_you.step_two', {
                interpolation: { escapeValue: false },
              }),
            }}
          />
          <br />

          <p>
            <a
              onClick={() => {
                snowplowTrack({
                  eventName: 'navigation_click',
                  property: 'contact_customer_support',
                })
              }}
              href="https://asanarebel.zendesk.com/"
              target="_blank"
              rel="noreferrer"
              className="cursor-pointer text-ar-green"
              dangerouslySetInnerHTML={{
                __html: t('thank_you.subscription_help', {
                  interpolation: { escapeValue: false },
                }),
              }}
            />
          </p>
          <br />
          {user?.recipientEmailAddress && (
            <p
              dangerouslySetInnerHTML={{
                __html: t('thank_you.gift_card', {
                  email: user.recipientEmailAddress,
                  interpolation: { escapeValue: false },
                }),
              }}
            />
          )}
          <br />

          <p
            dangerouslySetInnerHTML={{
              __html: t('thank_you.see_you', {
                interpolation: { escapeValue: false },
              }),
            }}
          />
        </div>
      </div>

      <ContinueButton
        onClick={onContinue}
        buttonText={t('thank_you.download')}
      />
    </>
  )
}
