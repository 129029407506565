import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useSnowplow from 'hooks/useSnowplow'
import { ContinueButton } from 'components/ContinueButton'
import { BulletPoint } from './BulletPoint'
import { ReactComponent as Heart } from './icons/heart.svg'
import { ReactComponent as Flexibility } from './icons/flexibility.svg'
import { ReactComponent as Stress } from './icons/stress.svg'
import { ReactComponent as Fat } from './icons/fat.svg'
import { ReactComponent as Core } from './icons/core.svg'
import { ReactComponent as Antioxidants } from './icons/antioxidants.svg'
import { ReactComponent as VitaminC } from './icons/c.svg'

interface Props {
  nextRoute: string
}

export const HealthForecast: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const navigate = useNavigate()

  const onButtonClick = () => {
    snowplowTrack({
      eventName: 'navigation_click',
      property: 'health_forecast',
    })

    navigate(nextRoute)
  }

  return (
    <>
      <div
        className="radius-4 container mx-auto my-12 w-[90%]
        max-w-[400px] rounded-[10px] px-4 text-center
        shadow-[0_0_40px_rgba(0,0,0,0.1)]">
        <h3
          className="m-h-[40px] font-link text-xs leading-[40px]
          text-ar-dark-gray sm:text-base sm:leading-[40px]">
          {t('health_forecast.headline')}
        </h3>

        <ul className="flex flex-col border-y-[1px] border-solid border-[#eeeff1] py-4 text-left">
          <BulletPoint icon={Heart} point={1} direction="down" />
          <BulletPoint icon={Flexibility} point={2} />
          <BulletPoint icon={Stress} point={3} direction="down" />
          <BulletPoint icon={Fat} point={4} direction="down" />
          <BulletPoint icon={Core} point={5} />
          <BulletPoint icon={Antioxidants} point={6} />
          <BulletPoint icon={VitaminC} point={7} />
        </ul>

        <small className="mb-1 inline-block h-[24px] font-body text-xs text-[#999c9f]">
          {t('health_forecast.footer')}
        </small>
      </div>
      <ContinueButton onClick={onButtonClick} />
    </>
  )
}
