import React from 'react'
import { ReactComponent as Chevron } from 'icons/chevron.svg'

interface Props {
  text: string
}

export const Question: React.FC<Props> = ({ text }) => {
  return (
    <>
      <div className="flex cursor-pointer justify-between">
        <div className="order-last text-ar-green">
          <Chevron width="25" height="25" className="mx-1" />
        </div>
        <p className="text-left font-title text-sm text-black">{text}</p>
      </div>
    </>
  )
}
