import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useSnowplow from 'hooks/useSnowplow'
import { Heading } from 'components/Heading'
import { PreLoadImage } from 'components/PreLoadImage'
import { useUser } from 'context/UserContext'

interface Props {
  text: string
  nextRoute: string
}

export const AgingFunnelStatementV1: React.FC<Props> = ({
  text,
  nextRoute,
}) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const { user } = useUser()
  const navigate = useNavigate()
  const location = useLocation()
  const genderBasedBackground =
    user.gender === 'm' ? 'bg-fitness-level-male' : 'bg-fitness-level-female'

  const onButtonClick = (num: number) => {
    snowplowTrack({
      eventName: 'navigation_click',
      property: location.pathname.split('/').pop(),
      label: num.toString(),
    })
    navigate(nextRoute)
  }

  return (
    <>
      <PreLoadImage background={genderBasedBackground} />
      <div className="container mx-auto px-4 text-center">
        <div className="m-8">
          <Heading
            textColor="black"
            text={t('agingFunnel.v1.statements.title')}
          />
        </div>
        <p className="font-lighter mt-2 text-center font-body text-sm text-black">
          {text}
        </p>

        <div className="mt-8">
          <ul className="flex flex-row flex-nowrap items-stretch justify-between justify-items-stretch">
            {[1, 2, 3, 4].map((item) => (
              <li
                key={item}
                className="mx-[5px] w-full rounded-md bg-[#f4f4f4] text-center">
                <span
                  id={`statement_${item}`}
                  onClick={() => onButtonClick(item)}
                  className="inline-block min-h-[69px] w-full cursor-pointer font-link text-[25px] leading-[69px] text-[#979797]">
                  {item}
                </span>
              </li>
            ))}
          </ul>
          <p className="font-ligther mt-3 inline-block w-1/2 pl-[5px] text-start font-body text-sm">
            {t('statements.disagree')}
          </p>
          <p className="font-ligther mt-3 inline-block w-1/2 pr-[5px] text-end font-body text-sm">
            {t('statements.agree')}
          </p>
        </div>
      </div>
    </>
  )
}
