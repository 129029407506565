import { useNavigate, useLocation } from 'react-router-dom'
import useLocalStorageState from 'use-local-storage-state'
import { useTranslation } from 'react-i18next'
import useSnowplow from 'hooks/useSnowplow'

interface Props {
  text: string
  nextRoute: string
}

export const ANSWERS = 'ar_quiz_answers'

export const AgingFunnelPersonalityTest: React.FC<Props> = ({
  text,
  nextRoute,
}) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const navigate = useNavigate()
  const location = useLocation()
  const [, setAnswers] = useLocalStorageState<number[]>(ANSWERS, {
    defaultValue: [],
  })

  const onButtonClick = (num: number) => {
    snowplowTrack({
      eventName: 'navigation_click',
      property: location.pathname.split('/').pop(),
      label: num.toString(),
    })

    setAnswers((allAnswers) => [...allAnswers, num])

    navigate(nextRoute)
  }

  return (
    <>
      <div className="container mx-auto px-4 text-center">
        <div className="m-8">{text}</div>

        <div className="mt-8">
          <ul className="flex flex-row flex-nowrap items-stretch justify-between justify-items-stretch">
            {[1, 2, 3, 4, 5].map((item) => (
              <li
                key={item}
                className="mx-[5px] w-full rounded-md bg-[#f4f4f4] text-center">
                <span
                  id={`statement_${item}`}
                  onClick={() => onButtonClick(item)}
                  className="inline-block min-h-[69px] w-full cursor-pointer font-link text-[25px] leading-[69px] text-[#979797]">
                  {item}
                </span>
              </li>
            ))}
          </ul>
          <p className="font-ligther mt-3 inline-block w-1/2 pl-[5px] text-start font-body text-sm">
            {t('statements.disagree')}
          </p>
          <p className="font-ligther mt-3 inline-block w-1/2 pr-[5px] text-end font-body text-sm">
            {t('statements.agree')}
          </p>
        </div>
      </div>
    </>
  )
}
