import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  buttonText?: string
  disabled?: boolean
  onClick: () => void
  disabledText?: string
  children?: ReactNode
  id?: string
}

export const DesicionButton: React.FC<Props> = ({
  buttonText,
  disabled = false,
  disabledText,
  onClick,
  children,
  id = 'continue_button',
}) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const translatedButtonText = () => {
    return buttonText || t('button_active')
  }

  const translatedDisabledText = () => {
    return disabledText || t('button_active')
  }

  return (
    <div>
      <button
        id={id}
        type="button"
        onClick={onClick}
        disabled={disabled}
        className={`text-md m-auto block w-full rounded-lg bg-ar-green p-7 p-5 text-center
        font-link text-ar-white focus:outline-none`}>
        {disabled ? translatedDisabledText() : translatedButtonText()}
      </button>
      {children}
    </div>
  )
}
