import { useEffect } from 'react'
import useLocalStorageState from 'use-local-storage-state'
import UserContext, { User } from 'context/UserContext'
// import PlanContext from 'context/PlanContext'
import 'react-toastify/dist/ReactToastify.css'
import { useLocation, useSearchParams } from 'react-router-dom'
import useApi from 'hooks/useApi'
import useSnowplow from 'hooks/useSnowplow'
import useTikTok from 'hooks/useTikTok'
import { RoutePaths } from 'route-paths'
import { FunnelRouter } from 'components/FunnelRouter'
import { AgingFunnelRouter } from 'components/AgingFunnelRouter'
import { V1AgingFunnelRouter } from 'components/AgingFunnelRouter/V1AgingFunnelRouter'
import { V2AgingFunnelRouter } from 'components/AgingFunnelRouter/V2AgingFunnelRouter'
import { V3AgingFunnelRouter } from 'components/AgingFunnelRouter/V3AgingFunnelRouter'
// import usePlanData from 'hooks/usePlanData'

export const LOCAL_STORAGE_USER_KEY = 'asanarebel.user'
export const LOCAL_STORAGE_DISCOUNT_COUPON = 'asanarebel.discount.coupon'

interface Props {
  locale: string
}

function App({ locale }: Props) {
  const [searchParams] = useSearchParams()
  const discountCodeParam = searchParams.get('discount_coupon')

  if (discountCodeParam) {
    localStorage.setItem(LOCAL_STORAGE_DISCOUNT_COUPON, discountCodeParam)
  }

  // const { selectedPlan: plan, setSelectedPlan: setPlan } = usePlanData(locale)

  const location = useLocation()

  const [user, setUser] = useLocalStorageState<User>(LOCAL_STORAGE_USER_KEY)

  const { createGuest, getExperiments } = useApi(locale)

  const { tikTokInit, tikTokPageView } = useTikTok()
  const { snowplowInit, snowplowTrackPageView } = useSnowplow()

  const isNotMagicLogin = location.pathname !== RoutePaths.MagicLogin

  const initializeTrackers = () => {
    if (!user) {
      return
    }

    tikTokInit(user)
    snowplowInit(`${user?.id}`, user?.experiments)
  }

  const isAgingFunnel = /\/pages\/ag\//.test(location.pathname)
  const currentUserExperiments = isAgingFunnel
    ? process.env.REACT_APP_AGING_EXPERIMENT!
    : process.env.REACT_APP_EXPERIMENTS || ''

  const createGuestUser = async () => {
    const parsedExperiments = currentUserExperiments.split(',')

    const guestUser = await createGuest(parsedExperiments)

    setUser({
      ...guestUser.user,
      authToken: guestUser.token,
      experiments: guestUser.experiments,
      assignments: Object.fromEntries(
        guestUser.experiments.map((e: any) => [e.name, e.variant]),
      ),
    })
  }

  const fetchUserAssignments = async (token: string) => {
    const response = await getExperiments({
      authToken: token,
      experiments: currentUserExperiments,
    })

    setUser({
      ...user,
      experiments: response.experiments,
      assignments: Object.fromEntries(
        response.experiments.map((e: any) => [e.name, e.variant]),
      ),
    })
  }

  const fetchLandingPageExperiments = (token: string) => {
    if (isAgingFunnel) {
      fetchUserAssignments(token)
      return
    }

    fetchUserAssignments(token)
  }

  useEffect(() => {
    if (!user?.id && isNotMagicLogin) {
      createGuestUser()
    } else if (!!user?.id && !!user?.authToken) {
      fetchLandingPageExperiments(user.authToken)
      initializeTrackers()
    }
  }, [user?.id]) // eslint-disable-line react-hooks/exhaustive-deps

  const googleTagPageView = () => {
    if (typeof gtag === 'undefined') {
      return
    }

    gtag('event', 'conversion', {
      send_to: 'AW-767545990/Dk7NCKKYg9kDEIal_-0C',
    })
  }

  useEffect(() => {
    // initial page view
    googleTagPageView()
  }, [])

  useEffect(() => {
    if (!user) {
      return
    }

    tikTokPageView(user)
    googleTagPageView()
    snowplowTrackPageView()
  }, [location]) // eslint-disable-line react-hooks/exhaustive-deps

  const renderRouter = () => {
    if (!user?.assignments) {
      return <FunnelRouter locale={locale} />
    }

    if (isAgingFunnel) {
      switch (user?.assignments['w_aging_wild_variants']) {
        case 'Variant-1':
          return <V1AgingFunnelRouter locale={locale} />
        case 'Variant-2':
          return <V2AgingFunnelRouter locale={locale} />
        case 'Variant-3':
          return <V3AgingFunnelRouter locale={locale} />
        default:
          return <AgingFunnelRouter locale={locale} />
      }
    }
    return <FunnelRouter locale={locale} />
  }

  if (!user) {
    return null
  }

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {renderRouter()}
    </UserContext.Provider>
  )
}

export default App
