import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUser } from 'context/UserContext'
import useSnowplow from 'hooks/useSnowplow'
import { ButtonWrapper } from 'components/ButtonWrapper'
import { ChoiceButton } from 'components/ChoiceButton'
import { Heading } from 'components/Heading'

interface Props {
  nextRoute: string
}

export const AgingFunnelActivity: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const navigate = useNavigate()
  const { user, setUser } = useUser()

  const genderBasedBackground =
    user.gender === 'm'
      ? 'bg-agingActivity-male'
      : 'bg-agingActivity-female bg-center'

  const headlineTranslated = () => {
    const currentYear = new Date().getFullYear()
    const getCurrentAge = currentYear - Number(user.dateOfBirth)

    const calculationYear =
      getCurrentAge > 19 && getCurrentAge < 30
        ? '20'
        : getCurrentAge > 29 && getCurrentAge < 40
        ? '30'
        : getCurrentAge > 39 && getCurrentAge < 50
        ? '40'
        : getCurrentAge > 49 && getCurrentAge < 60
        ? '50'
        : getCurrentAge > 59 && getCurrentAge < 70
        ? '60'
        : getCurrentAge

    return t('agingFunnel.activity.headline', {
      gender:
        user.gender === 'm'
          ? t('agingFunnel.activity.man')
          : t('agingFunnel.activity.woman'),
      age: calculationYear,
    })
  }

  const onButtonClick = (agingActivity: string) => {
    snowplowTrack({
      eventName: 'navigation_click',
      property: 'agingActivity',
      label: agingActivity,
    })

    setUser({ ...user, agingActivity })
    navigate(nextRoute)
  }

  return (
    <div
      className={`z-[-10] h-screen bg-cover bg-center bg-no-repeat ${genderBasedBackground}`}>
      <div className="mx-auto flex flex-col justify-between px-8 pt-9 lg:w-5/6 xl:w-5/12">
        <Heading text={headlineTranslated()} />
        <br />
        <Heading text={t('agingFunnel.activity.subheadline')} />

        <ButtonWrapper small>
          <ChoiceButton
            onClick={() => onButtonClick('yes')}
            value={t('agingFunnel.activity.activity_1')}
            id="yes"
            small
          />
          <ChoiceButton
            onClick={() => onButtonClick('no')}
            value={t('agingFunnel.activity.activity_2')}
            id="no"
            small
          />
          <ChoiceButton
            onClick={() => onButtonClick('sometimes')}
            value={t('agingFunnel.activity.activity_3')}
            id="sometimes"
            small
          />
        </ButtonWrapper>
      </div>
    </div>
  )
}
