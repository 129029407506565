import { useState, useEffect, useRef, ChangeEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUser } from 'context/UserContext'
import { useKeyPress } from 'hooks/useKeyPress'
import { useAutofocus } from 'hooks/useAutofocus'
import useSnowplow from 'hooks/useSnowplow'
import { ContinueButton } from 'components/ContinueButton'
import { Heading } from 'components/Heading'
import Input from 'components/Input'
import { UnitSwitcher } from 'components/UnitSwitcher'
import { PreLoadImage } from 'components/PreLoadImage'

interface Props {
  nextRoute: string
}

export const Weight: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const METRIC_LBS_KEY = 1
  const METRIC_KG_KEY = 2
  const WEIGHT_UNITS = [
    { key: METRIC_LBS_KEY, unit: t('metric_lbs') },
    { key: METRIC_KG_KEY, unit: t('metric_kg') },
  ]
  const { user, setUser } = useUser()
  const genderBasedBackground =
    user.gender === 'm' ? 'bg-weight-male' : 'bg-weight-female'
  const preloadBackground =
    user.gender === 'm' ? 'bg-goal-weight-male' : 'bg-goal-weight-female'
  const navigate = useNavigate()
  const [canContinue, setCanContinue] = useState(false)
  const [weight, setWeight] = useState('')
  const [weightType, setWeightType] = useState(METRIC_KG_KEY)
  const weightInput = useRef(null)

  const onContinue = () => {
    if (!canContinue) {
      return
    }

    snowplowTrack({
      eventName: 'navigation_click',
      property: 'weight',
      label: `${weight} ${
        WEIGHT_UNITS.find((w) => w.key === weightType)?.unit
      }`,
    })

    setUser({
      ...user,
      weight,
      weightType: weightType === METRIC_LBS_KEY ? 'lbs' : 'kg',
    })
    setTimeout(() => {
      navigate(nextRoute)
    }, 100)
  }

  const onWeightChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value

    if (/(^$|^\d{1,3}$)/.test(input)) {
      setWeight(input)
    }
  }

  useEffect(() => {
    if (weight === '') {
      return
    }

    if (weightType === METRIC_LBS_KEY) {
      setWeight(`${Math.round(parseInt(weight, 10) * 2.205)}`)
    } else {
      setWeight(`${Math.round(parseInt(weight, 10) / 2.205)}`)
    }
  }, [weightType]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setCanContinue(weight.length > 1)
  }, [weight])

  useAutofocus(weightInput)

  useKeyPress('ENTER', onContinue)

  return (
    <>
      <PreLoadImage background={preloadBackground} />
      <div
        className={`z-[-10] h-screen bg-ar-dark-gray bg-cover bg-center bg-no-repeat ${genderBasedBackground}`}>
        <div className="mx-auto flex h-screen flex-col justify-between px-8 pt-9 lg:w-5/6 xl:w-5/12">
          <Heading text={t('weight.headline')} />

          <div className="z-0 mx-auto mt-5 mb-44 w-full sm:mb-28">
            <UnitSwitcher
              units={WEIGHT_UNITS}
              selected={weightType}
              onUnitSelected={(unit) => setWeightType(unit)}
            />
            <Input
              placeholder={weightType === METRIC_KG_KEY ? '70' : '115'}
              value={weight}
              id="weight_input"
              onChange={onWeightChange}
              ref={weightInput}
            />
          </div>
        </div>
        <ContinueButton onClick={onContinue} disabled={!canContinue} />
      </div>
    </>
  )
}
