import React from 'react'
import useSnowplow from 'hooks/useSnowplow'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Logo } from 'icons/logo.svg'
import { RoutePaths } from 'route-paths'

interface Props {
  showLogin?: boolean | undefined
  color: string
}

export const Header: React.FC<Props> = ({ showLogin, color }) => {
  const dataProps = {
    'data-tracking-event-type': 'navigationClick',
    'data-tracking-element-type': 'login',
  }

  const navigate = useNavigate()
  const { snowplowTrack } = useSnowplow()

  return (
    <header className="m-auto grid grid-cols-2 px-8 pt-9 lg:w-5/6">
      <Logo height="30" width="35" className="text-white" fill={color} />
      {showLogin && (
        <div className="z-10 cursor-pointer text-right">
          <span
            onClick={() => {
              snowplowTrack({
                eventName: 'navigation_click',
                property: 'link_to_login',
              })
              navigate(RoutePaths.Login)
            }}
            {...dataProps}
            className={`font-link text-sm text-${color}`}>
            Log In
          </span>
        </div>
      )}
    </header>
  )
}
