import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useSnowplow from 'hooks/useSnowplow'
import { ContinueButton } from 'components/ContinueButton'
import { Heading } from 'components/Heading'

interface Props {
  nextRoute: string
}

export const AgingFunnelFeedbackControl: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const navigate = useNavigate()

  const onButtonClick = () => {
    snowplowTrack({
      eventName: 'navigation_click',
      property: 'feedback_control',
    })

    navigate(nextRoute)
  }

  return (
    <>
      <div className="container mx-auto my-16 px-4 text-center">
        <div className="flex justify-center">
          <img
            src={'/images/feedback_reasons.png'}
            className="w-72 text-center"
            alt="Asana Rebel reasons pictures"
          />
        </div>

        <div className="my-6 flex justify-center text-sm text-ar-dark-gray">
          <div className="w-1/2 text-right">
            <div className="mr-2 inline-block h-3 w-3 rounded-sm bg-green-200"></div>
            {t('agingFunnel.v2.feedback_control.legend_left')}
          </div>
          <div className="ml-20 w-1/2 text-left">
            <div className="mr-2 inline-block h-3 w-3 rounded-sm bg-ar-green"></div>
            {t('agingFunnel.v2.feedback_control.legend_right')}
          </div>
        </div>

        <div className="mt-10">
          <Heading
            textColor="ar-dark-gray"
            text={t('agingFunnel.v2.feedback_control.headline')}
          />

          <p className="my-4 font-body text-sm text-ar-dark-gray xl:text-center">
            {t('agingFunnel.v2.feedback_control.subheadline')}
          </p>
        </div>
      </div>
      <ContinueButton onClick={onButtonClick} />
    </>
  )
}
