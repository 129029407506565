import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useSnowplow from 'hooks/useSnowplow'
import { ContinueButton } from 'components/ContinueButton'
import { Heading } from 'components/Heading'

interface Props {
  nextRoute: string
}

export const AgingFunnelFeedbackGoalAge: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const navigate = useNavigate()

  const onButtonClick = () => {
    snowplowTrack({
      eventName: 'navigation_click',
      property: 'feedback_goal_age',
    })

    navigate(nextRoute)
  }

  return (
    <>
      <div className="container mx-auto px-4 text-center">
        <div className="my-60">
          <Heading
            textColor="ar-green"
            text={t('agingFunnel.v2.feedback_goal_age.headline')}
          />

          <p className="my-4 font-body text-sm text-ar-dark-gray xl:text-center">
            {t('agingFunnel.v2.feedback_goal_age.subheadline')}
          </p>
        </div>
      </div>
      <ContinueButton onClick={onButtonClick} />
    </>
  )
}
