import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import usePricing from 'hooks/usePricing'
import useSnowplow from 'hooks/useSnowplow'
import { Plan } from '.'
import { PlanBoxLoading } from './PlanBoxLoading'
import useApi, { CouponResponse } from 'hooks/useApi'
import { LOCAL_STORAGE_DISCOUNT_COUPON } from 'components/App'

interface Props {
  currency: string
  onClick: () => void
  plan: Plan
  locale: string
}

// this whole component needs splitting into functions and smaller components
export const PlanBox: React.FC<Props> = ({
  plan,
  currency,
  onClick,
  locale,
}) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const couponCode = localStorage.getItem(LOCAL_STORAGE_DISCOUNT_COUPON)
  const { getCoupon } = useApi(locale)
  const [couponLoaded, setCouponLoaded] = useState(false)
  const [discountCoupon, setDiscountCoupon] = useState<
    CouponResponse | undefined
  >()

  const { snowplowTrack } = useSnowplow()
  const {
    trial,
    discountedPrice,
    totalDiscountedPrice,
    totalPrice,
    campaignTotalPrice,
    monthlyPrice,
    monthlyDiscountedPrice,
    campaignMonthlyPrice,
  } = usePricing(plan, currency, discountCoupon)

  const interval = () => {
    switch (plan.intervalLength) {
      case 12:
        return t('annually')
      case 3:
        return t('quarterly')
      case 1:
        return t('monthly')
    }
  }

  const periodicallyTrialTranslated = () => {
    if (!trial?.amount) {
      return t('plans.billed_annually', {
        interval: interval(),
        price: totalPrice,
      })
    }

    return t(`plans.billed_with_trial.${trial.unit}`, {
      price: totalPrice,
      count: trial.amount,
    })
  }

  const billedPeriodicallyWithCoupon = () => {
    if (trial) {
      return periodicallyTrialTranslated()
    }

    if (isTotallyFree()) {
      return t('plans.billed_annually', {
        interval: interval(),
        price: totalPrice,
      })
    }

    return t('plans.billed_annually_with_discount', {
      interval: interval(),
      price: totalPrice,
      price_with_discount: totalDiscountedPrice,
    })
  }

  const billedPeriodically = (price: string) => {
    const formattedPrice = `${currency} ${price}`

    return t('plans.billed_annually', {
      interval: interval(),
      price: formattedPrice,
    })
  }

  const duration = () =>
    t('plans.months', {
      count: plan.intervalLength,
    })

  const isTotallyFree = () => {
    if (!discountCoupon) {
      return false
    }

    return (
      discountCoupon.discount_type === 'percent' &&
      discountCoupon.discount_percent === 100
    )
  }

  const onButtonClick = () => {
    snowplowTrack({
      eventName: 'navigation_click',
      property: 'add_to_cart',
      label: duration(),
    })
    onClick()
  }

  useEffect(() => {
    const fetchCoupon = async () => {
      if (!couponCode) {
        setCouponLoaded(true)
        return
      }

      try {
        const couponResponse = await getCoupon(couponCode, plan.code, currency)

        if (couponResponse.error) {
          setCouponLoaded(true)
          return
        }

        if (couponResponse.coupon_code === couponCode) {
          setCouponLoaded(true)
          setDiscountCoupon(couponResponse)
        }
      } catch {
        setCouponLoaded(true)
      }
    }
    fetchCoupon()
  }, [couponLoaded, setDiscountCoupon]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!couponLoaded) {
    return <PlanBoxLoading />
  } else {
    return (
      <div className="mt-5 justify-center sm:flex">
        <div className="box-content w-full border-2 border-ar-green bg-ar-white text-center md:flex md:text-left">
          <div className="md:mb-4 md:w-1/2 md:pl-8">
            <p className="mt-5 font-title text-sm uppercase text-ar-dark-gray">
              {duration()}
            </p>

            <p className="mt-4 font-link text-2xl text-ar-green line-through">
              {discountedPrice ? monthlyPrice : campaignMonthlyPrice}
            </p>

            <p className="mt-4 font-link text-2xl text-black">
              {`${
                discountedPrice ? monthlyDiscountedPrice : monthlyPrice
              } / ${t('plans.month')}`}
            </p>

            <p className="mt-4 font-body text-sm text-ar-green line-through">
              {discountedPrice ? '' : billedPeriodically(campaignTotalPrice)}
            </p>
            <span className="font-body text-sm text-ar-dark-gray">
              {discountedPrice || trial
                ? billedPeriodicallyWithCoupon()
                : billedPeriodically(totalPrice)}
            </span>

            <div className="md:hidden">
              <button
                id={`add_to_cart_mobile_${plan.code}`}
                type="button"
                onClick={onButtonClick}
                className="text-md m-auto mt-6 block w-4/5 rounded-lg bg-ar-green p-5 text-center font-link text-white focus:outline-none">
                {t('plans.call_to_action').toUpperCase()}
              </button>

              <div className="flex flex-col gap-4 p-8">
                <p className="font-body text-sm text-ar-dark-gray">
                  {t('plans.cancel_anytime')}
                </p>
                <p className="font-title text-sm text-black">
                  {t('plans.money_back')}
                </p>
              </div>
            </div>
          </div>
          <div className="hidden md:ml-32 md:block md:w-1/2">
            <button
              id={`add_to_cart_desktop_${plan.code}`}
              type="button"
              onClick={onButtonClick}
              className="text-md mt-12 block w-3/4 rounded-lg bg-ar-green p-4 text-center font-link text-white focus:outline-none">
              {t('plans.call_to_action').toUpperCase()}
            </button>

            <div className="mt-4 flex flex-col gap-4">
              <p className="font-body text-sm text-ar-dark-gray">
                {t('plans.cancel_anytime')}
              </p>
              <p className="font-title text-sm text-black">
                {t('plans.money_back')}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
