import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUser } from 'context/UserContext'
import useSnowplow from 'hooks/useSnowplow'
import { ContinueButton } from 'components/ContinueButton'
import { Heading } from 'components/Heading'
import { PreLoadImage } from 'components/PreLoadImage'

interface Props {
  nextRoute: string
}

export const AgingFunnelFeedbackAge: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const navigate = useNavigate()
  const { user } = useUser()
  const preloadBackground =
    user.gender === 'm'
      ? 'bg-agingActivity-male'
      : 'bg-agingActivity-female bg-center'

  const onButtonClick = () => {
    snowplowTrack({
      eventName: 'navigation_click',
      property: 'agingFeedbackAge',
    })

    navigate(nextRoute)
  }

  const currentYear = new Date().getFullYear()
  const getCurrentAge = currentYear - Number(user.dateOfBirth)

  const subheadlineTranslated = () => {
    const calculationYear =
      getCurrentAge > 19 && getCurrentAge < 30
        ? '20'
        : getCurrentAge > 29 && getCurrentAge < 40
        ? '30'
        : getCurrentAge > 39 && getCurrentAge < 50
        ? '40'
        : getCurrentAge > 49 && getCurrentAge < 60
        ? '50'
        : getCurrentAge > 59 && getCurrentAge < 70
        ? '60'
        : getCurrentAge

    return t('agingFunnel.feedback_age.subheadline', {
      gender:
        user.gender === 'm'
          ? t('agingFunnel.feedback_age.man')
          : t('agingFunnel.feedback_age.woman'),
      age: calculationYear,
    })
  }

  const subheadlineTranslatedGender = () => {
    const calculationYear =
      getCurrentAge < 40
        ? '20'
        : getCurrentAge < 50
        ? '25'
        : getCurrentAge < 60
        ? '30'
        : '40'

    return user.gender === 'm'
      ? t('agingFunnel.feedback_age.subheadline_male', {
          ageGoal: calculationYear,
        })
      : t('agingFunnel.feedback_age.subheadline_female', {
          ageGoal: calculationYear,
        })
  }

  return (
    <>
      <PreLoadImage background={preloadBackground} />
      <div className="container mx-auto px-4 text-center">
        <div className="my-60">
          <Heading
            textColor="ar-green"
            text={t('agingFunnel.feedback_age.headline')}
          />

          {getCurrentAge > 29 ? (
            <p className="my-4 font-body text-sm text-ar-dark-gray xl:text-center">
              {subheadlineTranslated()} {subheadlineTranslatedGender()}
            </p>
          ) : (
            ''
          )}
        </div>
      </div>
      <ContinueButton onClick={onButtonClick} />
    </>
  )
}
