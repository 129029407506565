import { useState, useEffect, useRef, ChangeEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUser } from 'context/UserContext'
import { useKeyPress } from 'hooks/useKeyPress'
import { useAutofocus } from 'hooks/useAutofocus'
import useSnowplow from 'hooks/useSnowplow'
import { ContinueButton } from 'components/ContinueButton'
import { Heading } from 'components/Heading'
import Input from 'components/Input'
import { UnitSwitcher } from 'components/UnitSwitcher'
import { PreLoadImage } from 'components/PreLoadImage'

interface Props {
  nextRoute: string
}

export const AgingFunnelHeight: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const METRIC_IN_KEY = 1
  const METRIC_CM_KEY = 2
  const HEIGHT_UNITS = [
    { key: METRIC_IN_KEY, unit: t('height.metric_in') },
    { key: METRIC_CM_KEY, unit: t('height.metric_cm') },
  ]
  const { user, setUser } = useUser()
  const genderBasedBackground =
    user.gender === 'm'
      ? 'bg-height-male-mobile md:bg-height-male'
      : 'bg-height-female'
  const preloadBackground =
    user.gender === 'm' ? 'bg-weight-male' : 'bg-weight-female'
  const navigate = useNavigate()
  const [canContinue, setCanContinue] = useState(false)
  const [height, setHeight] = useState('')
  const [heightType, setHeightType] = useState(METRIC_CM_KEY)
  const heightInput = useRef(null)

  const onContinue = () => {
    if (!canContinue) {
      return
    }

    snowplowTrack({
      eventName: 'navigation_click',
      property: 'height',
      label: `${height} ${
        HEIGHT_UNITS.find((m) => m.key === heightType)?.unit
      }`,
    })

    setUser({
      ...user,
      height,
      heightType: heightType === METRIC_IN_KEY ? 'in' : 'cm',
    })
    setTimeout(() => {
      navigate(nextRoute)
    }, 100)
  }

  const onHeightChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value

    if (/(^$|^\d{1,3}$)/.test(input)) {
      setHeight(input)
    }
  }

  useEffect(() => {
    if (height === '') {
      return
    }

    if (heightType === METRIC_IN_KEY) {
      setHeight(`${Math.round(parseInt(height, 10) / 2.54)}`)
    } else {
      setHeight(`${Math.round(parseInt(height, 10) * 2.54)}`)
    }
  }, [heightType]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setCanContinue(height.length > 1)
  }, [height])

  useAutofocus(heightInput)

  useKeyPress('ENTER', onContinue)

  return (
    <>
      <PreLoadImage background={preloadBackground} />
      <div
        className={`z-[-10] h-screen bg-ar-dark-gray bg-cover bg-center bg-no-repeat ${genderBasedBackground}`}>
        <div className="mx-auto flex h-screen flex-col justify-between px-8 pt-9 lg:w-5/6 xl:w-5/12">
          <Heading text={t('height.headline')} />

          <div className="z-0 mx-auto mt-5 mb-44 w-full sm:mb-28">
            <UnitSwitcher
              units={HEIGHT_UNITS}
              selected={heightType}
              onUnitSelected={(unit) => setHeightType(unit)}
            />
            <Input
              placeholder={heightType === METRIC_CM_KEY ? '160' : '82'}
              value={height}
              id="height_input"
              onChange={onHeightChange}
              ref={heightInput}
            />
          </div>
        </div>
        <ContinueButton onClick={onContinue} disabled={!canContinue} />
      </div>
    </>
  )
}
