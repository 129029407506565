/* eslint-disable camelcase */

import { User } from 'context/UserContext'

const passwordResetOrigin =
  process.env.REACT_APP_PW_RESET_ORIGIN || 'getstarted'

interface SubscriptionResponse {
  message: string
  subscription: SubscriptionObject
  errors: SubscriptionError
}

export interface SubscriptionError {
  code: string
  token: string
  message?: string
}

export interface SubscriptionObject {
  interval_in_months: number
  renewal_date: string
  expires_date: string
  duration_in_weeks: number
  ecommerce_event_data?: ECommerceEventData
}

interface ECommerceEventData {
  event: string
  ecommerce: ECommerceData
}

interface ECommerceData {
  purchase: ECommercePurchaseData
}

interface ECommercePurchaseData {
  actionField: ECommerceActionField
  products: ECommerceProduct[]
  userProperties: ECommerceUserProperties
}

interface ECommerceUserProperties {
  email_hashed: string
  client_ip_address: string | undefined
}

interface ECommerceProduct {
  id: string
  price: string
  coupon: string
}

interface ECommerceActionField {
  id: string
  currency: string
  coupon: string
  gross_price: string | number | null
  discount: string | number | null
  fee: string | number | null
  taxes: string | number | null
  proceeds: string | number | null
  ltv: string | number | null
}

interface ExperimentsResponse {
  experiments: Experiment[]
}

export interface Experiment {
  name: string
  variant: string
  version: number
}

interface GeoLocationResponse {
  country: string
  currency: string
  error?: string
  country_alphabetical_code: string
}

export interface PlansResponse {
  plans: PlanResponse[]
  error?: string
}

interface PlanShowResponse {
  plan: PlanResponse
  error?: string
}

export interface PlanResponse {
  code: string
  name: string
  description: string
  interval_length: number
  currencies: PlansCurrenciesResponse[]
}

interface PlansCurrenciesResponse {
  currency: string
  unit_amount: number
}

export interface CouponResponse {
  error?: string
  coupon_code: string
  name: string
  state: string
  description: string
  discount_type: string
  discount_percent: number | null
  invoice_description: string
  redeem_by_date: Date | null
  single_use: boolean
  max_redemptions: number | null
  applies_to_all_plans: boolean
  applies_to_all_items: boolean
  created_at: Date
  updated_at: Date
  deleted_at: Date | null
  duration: string
  applies_to_non_plan_charges: boolean
  redemption_resource: string
  max_redemptions_per_account: number
  coupon_type: string
  id: number
  discount_in_cents: Record<string, number>
  free_trial_amount: number
  free_trial_unit: string
  plan_codes: string[]
}

interface LatestSubscription {
  expires_date: string
  duration_in_weeks: number
  product_id: string
  currency: string
}

interface DetailedUser extends User {
  latest_subscription: LatestSubscription
}

interface MagicLoginResponse {
  token: string
  user: DetailedUser
}

interface GiftCardResponse {
  recipient_email_address?: string
  errors?: string
}

interface CancellationRequestResponse {
  message?: string
  errors?: string
}

export const API_BASE_URL = process.env.REACT_APP_ASANA_REBEL_API_URL!

export default function useApi(locale: string) {
  const requestWithBody = async (
    method: string,
    path: string,
    data: object,
    authToken = '',
  ) => {
    const res = await fetch(`${API_BASE_URL}${path}`, {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...(authToken && { Authorization: `Bearer ${authToken}` }),
      },
      body: JSON.stringify(Object.assign(data, { locale: locale }) || {}),
    })

    return res.json()
  }

  const requestQueryString = async (
    method: string,
    path: string,
    data: object,
    authToken = '',
  ) => {
    const params = new URLSearchParams(Object.assign(data) || {}).toString()
    const res = await fetch(`${API_BASE_URL}${path}?${params}`, {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...(authToken && { Authorization: `Bearer ${authToken}` }),
      },
    })

    return res.json()
  }

  const updateUser = ({ password, id, authToken }: User): Promise<any> =>
    requestWithBody('PATCH', `/api/v1/users/${id}`, { password }, authToken)

  const createUser = ({
    email,
    gender: sex,
    password,
    height,
    heightType: height_type,
    weight,
    weightType: weight_type,
    goalWeight: goal_weight,
    dateOfBirth: date_of_birth,
    goals,
    fitness: fitness_level,
    diet: diet_type,
    authToken,
  }: User): Promise<any> =>
    requestWithBody(
      'POST',
      '/api/v1/users',
      {
        email,
        sex,
        password,
        height,
        height_type,
        weight,
        weight_type,
        goal_weight,
        language: locale,
        date_of_birth,
        privacy_policy: true,
        terms_of_service: true,
        opt_in_at: new Date().toISOString(),
        goals,
        fitness_level,
        diet_type,
        platforms: ['web'],
      },
      authToken,
    )

  const createGuest = (experiments: string[] = []) =>
    requestWithBody('POST', '/api/v2/guest_users', {
      platforms: ['web'],
      experiments: experiments,
    })

  const signIn = ({
    email,
    password,
  }: {
    email: string
    password: string | undefined
  }): Promise<any> =>
    requestWithBody('POST', '/api/v1/sessions', { email, password })

  const getExperiments = ({
    experiments,
    authToken,
  }: {
    experiments: string
    authToken: string
  }): Promise<ExperimentsResponse> =>
    requestQueryString(
      'GET',
      '/api/v1/experiments',
      { experiments, new_user: '0' },
      authToken,
    )

  const getPlans = (): Promise<PlansResponse> =>
    requestQueryString('GET', '/api/v1/plans', {})

  const getPlan = (planId: string): Promise<PlanShowResponse> =>
    requestQueryString('GET', `/api/v1/plans/${planId}`, {})

  const getGeolocation = (): Promise<GeoLocationResponse> =>
    requestQueryString('GET', '/api/v1/geolocation', {})

  const passwordResetEmail = ({ email }: { email: string }) =>
    requestWithBody('POST', '/api/v1/password_reset_emails', {
      email,
      origin: passwordResetOrigin,
    })

  const resetPassword = ({
    token,
    password,
  }: {
    token: string
    password: string
  }) =>
    requestWithBody('POST', '/api/v1/reset_passwords', {
      token,
      new_password: password,
    })

  const createSubscription = ({
    authToken,
    recurlyToken,
    threeDToken,
    paypalToken: paypal_token,
    currency,
    planCode: plan_code,
    firstName: first_name,
    lastName: last_name,
    country,
    fullyDiscounted: fully_discounted,
    couponCode: discount_coupon,
    fbp,
    fbc,
  }: {
    authToken: string
    recurlyToken?: string
    threeDToken?: string
    paypalToken?: string
    currency: string
    planCode: string
    firstName?: string
    lastName?: string
    country?: string
    fullyDiscounted?: boolean
    couponCode?: string
    fbp?: string
    fbc?: string
  }): Promise<SubscriptionResponse> =>
    requestWithBody(
      'POST',
      '/api/v1/subscriptions',
      {
        'recurly-token': recurlyToken,
        paypal_token,
        'three-d-secure-token': threeDToken,
        currency,
        plan_code,
        discount_coupon,
        first_name,
        last_name,
        country,
        fully_discounted,
        fbp,
        fbc,
      },
      authToken,
    )

  const getCoupon = (
    discountCode: string,
    planId: string,
    currency: string,
  ): Promise<CouponResponse> =>
    requestQueryString('GET', `/api/v1/discount_coupons/${discountCode}`, {
      plan_id: planId,
      currency,
    })

  const magicLogin = ({
    token,
  }: {
    token: string
  }): Promise<MagicLoginResponse> =>
    requestWithBody('POST', '/api/v1/magic_login', { token })

  const createGiftCard = ({
    authToken,
    productCode: product_code,
    unitAmountInCents: unit_amount_in_cents,
    recipientEmailAddress: recipient_email_address,
    recipientFirstName: recipient_first_name,
    recipientLastName: recipient_last_name,
    gifterName: gifter_name,
    personalMessage: personal_message,
  }: {
    authToken: string
    productCode: string
    unitAmountInCents: number
    recipientEmailAddress: string
    recipientFirstName?: string
    recipientLastName?: string
    gifterName?: string
    personalMessage?: string
  }): Promise<GiftCardResponse> =>
    requestWithBody(
      'POST',
      '/api/v1/gift_cards',
      {
        product_code,
        unit_amount_in_cents,
        recipient_email_address,
        recipient_first_name,
        recipient_last_name,
        gifter_name,
        personal_message,
      },
      authToken,
    )

  const createCancellationRequest = ({
    authToken,
    type,
    subject,
    description,
  }: {
    authToken: string
    type: string
    subject: string
    description: string
  }): Promise<CancellationRequestResponse> => {
    return requestWithBody(
      'POST',
      '/api/v1/cancellation_requests',
      {
        subject,
        description,
        type,
      },
      authToken,
    )
  }

  return {
    createUser,
    updateUser,
    signIn,
    getExperiments,
    getPlan,
    getPlans,
    getGeolocation,
    getCoupon,
    createSubscription,
    passwordResetEmail,
    resetPassword,
    createGuest,
    magicLogin,
    createGiftCard,
    createCancellationRequest,
  }
}
