import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUser } from 'context/UserContext'
import useSnowplow from 'hooks/useSnowplow'
import { ButtonWrapper } from 'components/ButtonWrapper'
import { ChoiceButton } from 'components/ChoiceButton'
import { Heading } from 'components/Heading'
import { PreLoadImage } from 'components/PreLoadImage'

interface Props {
  nextRoute: string
}

export const Diet: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const navigate = useNavigate()
  const { user, setUser } = useUser()

  const onButtonClick = (diet: string) => {
    snowplowTrack({
      eventName: 'navigation_click',
      property: 'diet',
      label: diet,
    })

    setUser({ ...user, diet })
    navigate(nextRoute)
  }

  return (
    <div className="z-[-10] h-screen bg-diet bg-cover bg-center bg-no-repeat">
      <PreLoadImage src={'/images/feedback_experts.png'} />
      <div className="mx-auto flex flex-col justify-between px-8 pt-9 lg:w-5/6 xl:w-5/12">
        <Heading text={t('diet.headline')} />

        <ButtonWrapper small>
          <ChoiceButton
            onClick={() => onButtonClick('anything')}
            value={t('diet.diet_1')}
            id="no_preference"
            small
          />
          <ChoiceButton
            onClick={() => onButtonClick('vegetarian')}
            value={t('diet.diet_2')}
            id="vegetarian"
            small
          />
          <ChoiceButton
            onClick={() => onButtonClick('vegan')}
            value={t('diet.diet_3')}
            id="vegan"
            small
          />
          <ChoiceButton
            onClick={() => onButtonClick('keto')}
            value={t('diet.diet_4')}
            id="keto"
            small
          />
          <ChoiceButton
            onClick={() => onButtonClick('other')}
            value={t('diet.diet_5')}
            id="other"
            small
          />
        </ButtonWrapper>
      </div>
    </div>
  )
}
