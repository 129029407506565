import {
  useRef,
  useMemo,
  useState,
  forwardRef,
  useImperativeHandle,
  ReactNode,
} from 'react'
import { useTranslation } from 'react-i18next'
import countryList from 'react-select-country-list'
import {
  CardNumberElement,
  CardMonthElement,
  CardYearElement,
  CardCvvElement,
  ThreeDSecureAction,
  UseRecurlyInstance,
} from '@recurly/react-recurly'
import * as Sentry from '@sentry/react'
import useApi, { SubscriptionObject } from 'hooks/useApi'
import useFacebookCookies from 'hooks/useFacebookCookies'
import Select from 'react-select'
import cardsImg from './cards.png'

interface Props {
  children?: ReactNode
  fullyDiscounted: boolean
  recurly: UseRecurlyInstance
  setPaymentOption: (arg0: string) => void
  paymentOption: string
  handleError: (arg0: Error | string) => void
  setLoading: (arg0: boolean) => void
  authToken: string
  currency: string
  planCode: string
  locale: string
  userCountry?: string
  trackSuccessfulSubscription: (arg0: SubscriptionObject) => void
  couponCode?: string | null
}

interface CountrySelect {
  value: string
  label: string | null
}

type Ref = ReactNode | null

export const CreditCard = forwardRef<Ref, Props>(
  (
    {
      recurly,
      fullyDiscounted,
      setPaymentOption,
      paymentOption,
      handleError,
      setLoading,
      authToken,
      currency,
      planCode,
      locale,
      userCountry,
      trackSuccessfulSubscription,
      couponCode,
    },
    ref,
  ) => {
    useImperativeHandle(ref, () => ({
      submitPayment() {
        handleCc({})
      },
    }))

    const { t } = useTranslation('translation', {
      useSuspense: false,
    })

    const { fbp, fbc } = useFacebookCookies()

    const formRef = useRef<HTMLFormElement>(null)

    const firstNameRef = useRef<HTMLInputElement>(null)
    const lastNameRef = useRef<HTMLInputElement>(null)

    const countryOptions = useMemo(() => countryList().getData(), [])
    const [actionToken, setActionToken] = useState('')

    const { createSubscription } = useApi(locale)

    const [country, setCountry] = useState<CountrySelect>(() => {
      if (!userCountry) {
        return { value: '', label: null }
      } else {
        return {
          value: userCountry,
          label: countryList().valueMap[userCountry.toLowerCase()],
        }
      }
    })

    const selectOptions = (
      styles: any,
      {
        isDisabled,
        isFocused,
        isSelected,
      }: { isDisabled: boolean; isFocused: boolean; isSelected: boolean },
    ) => ({
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? '#00D186'
        : isFocused
        ? '#7fe8c2'
        : null,
      color: isDisabled ? '#ccc' : isSelected ? 'white' : '#2D2D2D',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? '#00D186' : '#7fe8c2'),
      },
    })

    const handleThreeD = (token: { id: string }) => {
      setLoading(true)
      handleCc({ threeDToken: token.id })
    }

    const createBackendSubscription = useMemo(
      () =>
        async ({ recurlyToken = '', threeDToken = '', paypalToken = '' }) => {
          try {
            const firstName = !!firstNameRef?.current
              ? firstNameRef.current.value
              : ''
            const lastName = !!lastNameRef?.current
              ? lastNameRef.current.value
              : ''

            const { subscription, errors } = await createSubscription({
              authToken,
              recurlyToken,
              threeDToken,
              paypalToken,
              currency,
              planCode,
              firstName,
              lastName,
              fbp,
              fbc,
              country: country.value,
              ...(fullyDiscounted && { fullyDiscounted }),
              ...(couponCode && { couponCode }),
            })

            if (errors) {
              handleError(errors.message || t('errors.unexpected_error'))

              if (errors.code === '3ds-required') {
                setActionToken(errors.token)
              }
            } else {
              trackSuccessfulSubscription(subscription)
            }
          } catch (error) {
            Sentry.captureException(error)
            handleError(t('errors.unexpected_error'))
          }
        },
      [
        fbp,
        fbc,
        currency,
        fullyDiscounted,
        planCode,
        firstNameRef,
        lastNameRef,
        couponCode,
        country,
        trackSuccessfulSubscription,
        handleError,
        createSubscription,
        t,
        authToken,
      ],
    )

    const handleCc = ({
      threeDToken,
    }: {
      threeDToken?: string | undefined
    }) => {
      if (fullyDiscounted) {
        return createBackendSubscription({})
      }

      if (!formRef.current) {
        return
      }

      recurly.token(formRef.current, (error, token) => {
        if (error) {
          handleError(error)
        } else {
          createBackendSubscription({ recurlyToken: token.id, threeDToken })
        }
      })
    }

    return (
      <div className="mt-5 rounded-lg p-6 shadow-lg ring-1 ring-ar-white">
        <div
          className="flex cursor-pointer justify-between"
          onClick={() => setPaymentOption('cc')}>
          <h3 className="text-md font-title xl:text-sm">
            {t('checkout.card')}
          </h3>
          <img src={cardsImg} alt="Credit Cards" width="134" height="24" />
        </div>
        {!fullyDiscounted && (
          <form
            ref={formRef}
            className={`mt-5 flex flex-col justify-evenly space-y-4 ${
              paymentOption === 'cc' ? 'block' : 'hidden'
            }`}>
            <label
              htmlFor="first_name"
              className="font-body text-sm text-ar-dark-gray">
              {t('checkout.name')}
            </label>
            <div className="grid w-11/12 grid-cols-1 space-y-4 sm:grid-cols-2 sm:space-y-0 sm:space-x-6">
              <input
                type="text"
                id="first_name"
                data-recurly="first_name"
                ref={firstNameRef}
                placeholder={t('checkout.name_placeholder')}
                className="rounded-lg border border-gray-300 p-4 font-body text-sm focus:outline-none"
              />
              <input
                type="text"
                id="last_name"
                data-recurly="last_name"
                ref={lastNameRef}
                placeholder={t('checkout.last_name_placeholder')}
                className="rounded-lg border border-gray-300 p-4 font-body text-sm focus:outline-none"
              />
            </div>
            <label className="font-body text-sm text-ar-dark-gray">
              {t('checkout.cc_number')}
            </label>
            <CardNumberElement
              className="w-11/12 rounded-lg p-3 ring-1 ring-gray-300"
              style={{
                fontColor: '#2D2D2D',
                fontSize: '0.875rem',
                placeholder: {
                  content: '•••• •••• •••• ••••',
                  color: '#cacaca',
                },
              }}
            />
            <label className="font-body text-sm text-ar-dark-gray">
              {t('checkout.expiration_date')}
            </label>
            <div className="grid w-11/12 grid-cols-1 space-y-4 sm:grid-cols-2 sm:space-y-0 sm:space-x-6">
              <CardMonthElement
                className="rounded-lg p-3 font-body text-sm ring-1 ring-gray-300"
                inputType="select"
                style={{
                  fontColor: '#2D2D2D',
                  fontSize: '0.875rem',
                  placeholder: {
                    content: 'MM',
                    color: '#cacaca',
                  },
                }}
              />
              <CardYearElement
                className="rounded-lg p-3 font-body text-sm ring-1 ring-gray-300"
                inputType="select"
                style={{
                  fontColor: '#2D2D2D',
                  fontSize: '0.875rem',
                  placeholder: {
                    content: 'YY',
                    color: '#cacaca',
                  },
                }}
              />
            </div>
            <label className="font-body text-sm text-ar-dark-gray">CVV</label>
            <CardCvvElement
              className="w-11/12 rounded-lg p-3 font-body text-sm ring-1 ring-gray-300"
              style={{
                fontColor: '#2D2D2D',
                fontSize: '0.875rem',
                placeholder: {
                  content: '•••',
                  color: '#cacaca',
                },
              }}
            />
            <label className="font-body text-sm text-ar-dark-gray">
              {t('checkout.billing_info')}
            </label>
            <div className="grid w-11/12 grid-cols-1 space-y-4 sm:grid-cols-2 sm:space-y-0 sm:space-x-6">
              <Select
                className="z-0 font-body text-sm text-ar-dark-gray"
                options={countryOptions}
                placeholder={t('checkout.country_placeholder')}
                value={country}
                onChange={(c) => setCountry(c as CountrySelect)}
                menuPlacement="top"
                styles={{
                  input: (provided) => ({
                    ...provided,
                    padding: '1rem',
                    margin: 0,
                  }),
                  control: (provided) => ({
                    ...provided,
                    borderRadius: '0.5rem',
                    border: '1px solid #d1d5db',
                    boxShadow: 'none',
                    '&:hover': {
                      border: '1px solid #d1d5db',
                    },
                  }),
                  option: selectOptions,
                }}
              />
              <input
                id="zip_code"
                className="rounded-lg border border-gray-300 p-4 font-body text-sm focus:outline-none"
                type="text"
                data-recurly="postal_code"
                placeholder={t('checkout.zipcode_placeholder')}
              />
            </div>
            {actionToken && (
              <ThreeDSecureAction
                className="h-400"
                actionTokenId={actionToken}
                onToken={handleThreeD}
                onError={handleError}
              />
            )}
          </form>
        )}
      </div>
    )
  },
)
