import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useSnowplow from 'hooks/useSnowplow'
import { ContinueButton } from 'components/ContinueButton'
import { Heading } from 'components/Heading'
import feedbackGoalWeight from './success.png'
import { PreLoadImage } from 'components/PreLoadImage'

interface Props {
  nextRoute: string
}

export const AgingFunnelFeedbackGoalWeight: React.FC<Props> = ({
  nextRoute,
}) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const navigate = useNavigate()

  const onButtonClick = () => {
    snowplowTrack({
      eventName: 'navigation_click',
      property: 'feedback_goalweight',
    })

    navigate(nextRoute)
  }

  return (
    <>
      <PreLoadImage background="bg-diet" />
      <div className="container mx-auto mt-48 px-4 text-center">
        <div className="flex justify-center">
          <img
            src={feedbackGoalWeight}
            className="text-center"
            alt="Checkmark"
          />
        </div>

        <div className="mt-6">
          <Heading
            textColor="ar-dark-gray "
            text={t('feedback_goalweight.headline')}
          />

          <p className="my-4 font-body text-sm text-ar-dark-gray xl:text-center">
            {t('feedback_goalweight.subheadline')}
          </p>
        </div>
      </div>
      <ContinueButton onClick={onButtonClick} />
    </>
  )
}
