import React from 'react'

interface Props {
  id?: string
  value: string
  selected: boolean
  onClick: () => void
}

export const EmotionButton: React.FC<Props> = ({
  value,
  selected,
  onClick,
  id,
}) => {
  return (
    <div className={`mx-2 inline-block`}>
      <button
        type="button"
        onClick={onClick}
        id={id}
        className={` rounded-md border p-2
                    ${
                      selected
                        ? 'bg-ar-green text-white'
                        : 'hover:bg-ar-green hover:text-white'
                    } `}>
        {value}
      </button>
    </div>
  )
}
