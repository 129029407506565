import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUser } from 'context/UserContext'
import { useKeyPress } from 'hooks/useKeyPress'
import { useAutofocus } from 'hooks/useAutofocus'
import useSnowplow from 'hooks/useSnowplow'
import { ContinueButton } from 'components/ContinueButton'
import { Heading } from 'components/Heading'
import Input from 'components/Input'

interface Props {
  nextRoute: string
}

export const Password: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const navigate = useNavigate()
  const { user, setUser } = useUser()
  const [password, setPassword] = useState('')
  const [canContinue, setCanContinue] = useState(false)
  const passwordInput = useRef(null)

  const onContinue = () => {
    if (!canContinue) {
      return
    }

    snowplowTrack({
      eventName: 'navigation_click',
      property: 'password',
    })

    setUser({ ...user, password, hasPassword: true })
    setTimeout(() => {
      navigate(nextRoute)
    }, 100)
  }

  useEffect(() => {
    setCanContinue(password.length > 5)
  }, [password])

  useAutofocus(passwordInput)

  useKeyPress('ENTER', onContinue)

  return (
    <div className="mx-auto flex flex-col justify-start px-8 pt-9 lg:w-5/6 xl:w-5/12">
      <Heading text={t('password.headline')} textColor="ar-dark-gray" />

      <div className="mt-8">
        <Input
          placeholder={t('password.placeholder')}
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          type="password"
          id="password_input"
          inputMode="text"
          darkMode={false}
          ref={passwordInput}
        />
        <span className="p-2 font-body text-sm text-ar-dark-gray text-opacity-80">
          {t('password.password_length')}
        </span>
      </div>

      <ContinueButton onClick={onContinue} disabled={!canContinue} />
    </div>
  )
}
