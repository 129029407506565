import { useState, useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useTikTok from 'hooks/useTikTok'
import useSnowplow from 'hooks/useSnowplow'
import useGoogleTag from 'hooks/useGoogleTag'
import usePlanData from 'hooks/usePlanData'
import { useUser } from 'context/UserContext'
import { usePlan } from 'context/PlanContext'
import { Benefits } from 'components/Benefits'
import { ContinueButton } from 'components/ContinueButton'
import { FAQ } from 'components/FAQ'
import { Heading } from 'components/Heading'
import { PlanBox } from './PlanBox'
import { PlanBoxesLoading } from './PlanBoxesLoading'
import { Reviews } from './Reviews'
import { SubHeading } from 'components/SubHeading'
import { SpecialOfferModal } from 'components/Modal/SpecialOffer'

export interface Plan {
  code: string
  name: string
  intervalLength: number
  currencies: PlanCurrency[]
}

interface PlanCurrency {
  currency: string
  unitAmount: number
}

interface Props {
  locale: string
  nextRoute: string
}

export const Plans: React.FC<Props> = ({ locale, nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const navigate = useNavigate()
  const { user, setUser } = useUser()
  const { setPlan } = usePlan()
  const { plans, currency, country } = usePlanData(locale)
  const [showModal, setShowModal] = useState<boolean>(false)

  const { tikTokTrack } = useTikTok()
  const { snowplowTrack } = useSnowplow()
  const { googleTrackInitiateCheckout } = useGoogleTag()

  const trackInitiateCheckout = (plan: Plan) => {
    const planCurrencyBased = plan.currencies.find(
      (c) => c.currency === currency,
    )
    const value = planCurrencyBased?.unitAmount!

    tikTokTrack({
      userId: user.id!,
      eventName: 'InitiateCheckout',
      data: {
        currency,
        value,
        content_id: plan.code,
      },
    })

    googleTrackInitiateCheckout(value, currency, user.id!)

    snowplowTrack({
      eventName: 'initiate_checkout',
      property: currency,
      value: value?.toString() ?? '',
    })
  }

  const onContinue = (plan: Plan) => {
    trackInitiateCheckout(plan)
    setUser({ ...user, country, currency })
    setPlan({ ...plan })
    navigate(nextRoute)
  }

  useEffect(() => {
    const displayModal = setTimeout(() => setShowModal(true), 15000)

    return () => clearTimeout(displayModal)
  }, [])

  return (
    <>
      {showModal && plans && plans[0] && (
        <SpecialOfferModal
          open={showModal}
          plan={plans[0]}
          currency={currency}
          onClick={() => onContinue(plans[0])}
          onClose={() => setShowModal(false)}
        />
      )}
      <div className="absolute h-400 w-full bg-ar-green lg:rounded-b-4xl" />

      <div className="relative">
        <div className="m-auto px-8 pt-9 lg:w-2/3">
          <Heading text={t('plans.headline')} centered />
          <Heading text={t('plans.subheadline')} centered />

          <SubHeading text={t('plans.subtitle')} centered />

          {!plans?.length ? (
            <PlanBoxesLoading />
          ) : (
            plans.map((plan: Plan) => (
              <PlanBox
                key={plan.code}
                plan={plan}
                onClick={() => onContinue(plan)}
                currency={currency}
                locale={locale}
              />
            ))
          )}

          <Benefits />
          <Reviews />
          <FAQ />

          <p
            className="mt-8 mb-40 text-center font-body text-sm text-ar-dark-gray"
            dangerouslySetInnerHTML={{
              __html: t('plans.terms_agreement', {
                link: 'https://asanarebel.com/terms-of-use/',
                terms_of_service: t('plans.terms_of_service'),
                interpolation: {
                  escapeValue: false,
                },
              }),
            }}
          />
          {plans && plans.length > 0 && (
            <ContinueButton
              id="add_to_cart"
              onClick={() => {
                snowplowTrack({
                  eventName: 'navigation_click',
                  property: 'add_to_cart',
                  label: plans[0]?.name,
                })
                onContinue(plans[0])
              }}
              buttonText={t('plans.call_to_action')}>
              <span className="mt-3 block w-full text-center font-body text-sm text-ar-dark-gray">
                {t('plans.cancel_anytime')}
              </span>
            </ContinueButton>
          )}
        </div>
      </div>
      <ToastContainer position="bottom-center" hideProgressBar />
    </>
  )
}
