import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useSnowplow from 'hooks/useSnowplow'
import { ContinueButton } from 'components/ContinueButton'
import { Heading } from 'components/Heading'

interface Props {
  nextRoute: string
}

export const AgingFunnelFeedbackExperts: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const navigate = useNavigate()

  const onButtonClick = () => {
    snowplowTrack({
      eventName: 'navigation_click',
      property: 'feedback_experts',
    })

    navigate(nextRoute)
  }

  return (
    <>
      <div className="container mx-auto px-4 text-center">
        <div className="flex justify-center">
          <img
            src={'/images/feedback_experts.png'}
            className="text-center"
            alt="Asana Rebel experts pictures"
          />
        </div>

        <div className="mt-6">
          <Heading
            textColor="ar-dark-gray "
            text={t('feedback_experts.headline')}
          />

          <p className="my-4 font-body text-sm text-ar-dark-gray xl:text-center">
            {t('feedback_experts.subheadline')}
          </p>
        </div>
      </div>
      <ContinueButton onClick={onButtonClick} />
    </>
  )
}
