import { useState, useEffect, useRef, ChangeEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUser } from 'context/UserContext'
import { useKeyPress } from 'hooks/useKeyPress'
import { useAutofocus } from 'hooks/useAutofocus'
import useSnowplow from 'hooks/useSnowplow'
import { ContinueButton } from 'components/ContinueButton'
import { Heading } from 'components/Heading'
import Input from 'components/Input'

interface Props {
  nextRoute: string
}

export const AgingFunnelGoalAge: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const { user, setUser } = useUser()
  const genderBasedBackground =
    user.gender === 'm'
      ? 'bg-goals-selection-male'
      : 'bg-goals-selection-female'
  const navigate = useNavigate()
  const [canContinue, setCanContinue] = useState(false)
  const [age, setAge] = useState('')
  const ageInput = useRef(null)

  const onContinue = () => {
    if (!canContinue) {
      return
    }

    snowplowTrack({
      eventName: 'navigation_click',
      property: 'goal_age',
      label: age,
    })

    setUser({ ...user, goalAge: `${age}` })
    setTimeout(() => {
      navigate(nextRoute)
    }, 100)
  }

  const onAgeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value

    if (/(^$|^\d{1,2}$)/.test(input)) {
      setAge(input)
    }
  }

  useKeyPress('ENTER', onContinue)

  useEffect(() => {
    setCanContinue(/^\d{2}$/.test(`${age}`))
  }, [age])

  useAutofocus(ageInput)

  return (
    <>
      <div
        className={`z-[-10] h-screen bg-ar-dark-gray bg-cover bg-center bg-no-repeat ${genderBasedBackground}`}>
        <div className="mx-auto flex h-screen flex-col px-8 pt-9 lg:w-5/6 xl:w-5/12">
          <Heading text={t('agingFunnel.v2.goal_age.headline')} />
          <div className="w-100 z-0 mx-auto mt-5 mb-44 grid h-full w-full content-end sm:mb-28">
            <Input
              placeholder="30"
              value={age}
              id="age_input"
              onChange={onAgeChange}
              ref={ageInput}
            />
          </div>
        </div>
      </div>
      <ContinueButton onClick={onContinue} disabled={!canContinue} />
    </>
  )
}
