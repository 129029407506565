import { useTranslation } from 'react-i18next'
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
import { useUser, User } from 'context/UserContext'
import { useAutoRedirect } from 'hooks/useAutoRedirect'
import { RoutePaths } from 'route-paths'
import { FeedbackPersonalize } from 'pages/FeedbackPersonalize'
import { Gender } from 'pages/Gender'
import { Goals } from 'pages/Goals'
import { GoalSelection } from 'pages/GoalSelection'
import { FeedbackGoalset } from 'pages/FeedbackGoalset'
import { FeedbackGoalChoice } from 'pages/FeedbackGoalChoice'
import { Height } from 'pages/Height'
import { Weight } from 'pages/Weight'
import { GoalWeight } from 'pages/GoalWeight'
import { FeedbackGoalWeight } from 'pages/FeedbackGoalWeight'
import { FitnessLevel } from 'pages/FitnessLevel'
import { Age } from 'pages/Age'
import { FeedbackTenMinutes } from 'pages/FeedbackTenMinutes'
import { Diet } from 'pages/Diet'
import { FeedbackExperts } from 'pages/FeedbackExperts'
import { Password } from 'pages/Password'
import { Email } from 'pages/Email'
import { Login } from 'pages/Login'
import { ForgotPassword } from 'pages/ForgotPassword'
import { PasswordReset } from 'pages/PasswordReset'
import { Loading } from 'pages/Loading'
import { Plans } from 'pages/Plans'
import { Checkout } from 'pages/Checkout'
import { ThankYou } from 'pages/ThankYou'
import { MagicLogin } from 'components/MagicLogin'
import { GiftCard } from 'pages/GiftCard'
import { Statement } from 'pages/Statement'
import { HealthForecast } from 'pages/HealthForecast'
import { CancellationRequest } from 'pages/CancellationRequest'
import { CancellationRequestSuccess } from 'pages/CancellationRequestSuccess'

interface Props {
  locale: string
}

const ProtectedRoute = ({
  user,
  redirectPath = RoutePaths.Login,
}: {
  user: User | undefined
  redirectPath?: string
}) => {
  if (!user?.authToken || !user.email) {
    return <Navigate to={redirectPath} replace />
  }

  return <Outlet />
}

const cancellationRedirectPath = () => {
  const redirectPath = window.location.pathname + window.location.search
  return `${RoutePaths.Login}?redirect_path=${redirectPath}`
}

export const FunnelRouter: React.FC<Props> = ({ locale }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { user } = useUser()

  useAutoRedirect()

  return (
    <Routes>
      <Route
        path={RoutePaths.Root}
        element={<Navigate to={RoutePaths.Gender} />}
      />
      <Route
        path={RoutePaths.Gender}
        element={<Gender nextRoute={RoutePaths.Goals} />}
      />
      <Route
        path={RoutePaths.Goals}
        element={<Goals nextRoute={RoutePaths.GoalSelection} />}
      />
      <Route
        path={RoutePaths.GoalSelection}
        element={<GoalSelection nextRoute={RoutePaths.FeedbackGoalset} />}
      />
      <Route
        path={RoutePaths.FeedbackGoalset}
        element={<FeedbackGoalset nextRoute={RoutePaths.FeedbackGoalChoice} />}
      />
      <Route
        path={RoutePaths.FeedbackGoalChoice}
        element={<FeedbackGoalChoice nextRoute={RoutePaths.StatementOne} />}
      />
      <Route
        path={RoutePaths.StatementOne}
        element={
          <Statement
            text={t('statements.one')}
            nextRoute={RoutePaths.StatementTwo}
          />
        }
      />
      <Route
        path={RoutePaths.StatementTwo}
        element={
          <Statement
            text={t('statements.two')}
            nextRoute={RoutePaths.StatementThree}
          />
        }
      />
      <Route
        path={RoutePaths.StatementThree}
        element={
          <Statement
            text={t('statements.three')}
            nextRoute={RoutePaths.StatementFour}
          />
        }
      />
      <Route
        path={RoutePaths.StatementFour}
        element={
          <Statement
            text={t('statements.four')}
            nextRoute={RoutePaths.FitnessLevel}
          />
        }
      />
      <Route
        path={RoutePaths.FitnessLevel}
        element={<FitnessLevel nextRoute={RoutePaths.Age} />}
      />
      <Route
        path={RoutePaths.Age}
        element={<Age nextRoute={RoutePaths.FeedbackTenMinutes} />}
      />
      <Route
        path={RoutePaths.FeedbackTenMinutes}
        element={<FeedbackTenMinutes nextRoute={RoutePaths.Height} />}
      />
      <Route
        path={RoutePaths.Height}
        element={<Height nextRoute={RoutePaths.Weight} />}
      />
      <Route
        path={RoutePaths.Weight}
        element={<Weight nextRoute={RoutePaths.GoalWeight} />}
      />
      <Route
        path={RoutePaths.GoalWeight}
        element={<GoalWeight nextRoute={RoutePaths.FeedbackGoalWeight} />}
      />
      <Route
        path={RoutePaths.FeedbackGoalWeight}
        element={<FeedbackGoalWeight nextRoute={RoutePaths.Diet} />}
      />
      <Route
        path={RoutePaths.Diet}
        element={<Diet nextRoute={RoutePaths.FeedbackExperts} />}
      />
      <Route
        path={RoutePaths.FeedbackExperts}
        element={<FeedbackExperts nextRoute={RoutePaths.FeedbackRisks} />}
      />
      <Route
        path={RoutePaths.FeedbackRisks}
        element={<HealthForecast nextRoute={RoutePaths.Password} />}
      />
      <Route
        path={RoutePaths.Password}
        element={<Password nextRoute={RoutePaths.Email} />}
      />
      <Route
        path={RoutePaths.Email}
        element={<Email locale={locale} nextRoute={RoutePaths.Loading} />}
      />
      <Route path={RoutePaths.Login} element={<Login locale={locale} />} />
      <Route
        path={RoutePaths.ForgotPassword}
        element={<ForgotPassword locale={locale} />}
      />
      <Route
        path={RoutePaths.PasswordReset}
        element={<PasswordReset locale={locale} />}
      />
      <Route
        path={RoutePaths.MagicLogin}
        element={<MagicLogin locale={locale} />}
      />
      <Route element={<ProtectedRoute user={user} />}>
        <Route
          path={RoutePaths.Loading}
          element={<Loading locale={locale} />}
        />
        <Route
          path={RoutePaths.Plans}
          element={<Plans locale={locale} nextRoute={RoutePaths.Checkout} />}
        />
        <Route
          path={RoutePaths.Checkout}
          element={
            <Checkout
              locale={locale}
              recurlyPublicKey={process.env.REACT_APP_RECURLY_PUBLIC_KEY || ''}
              nextRoute={RoutePaths.ThankYou}
            />
          }
        />
        <Route
          path={RoutePaths.GiftCard}
          element={<GiftCard locale={locale} nextRoute={RoutePaths.ThankYou} />}
        />
        <Route path={RoutePaths.ThankYou} element={<ThankYou />} />
        <Route
          path={RoutePaths.CancellationRequestSuccess}
          element={<CancellationRequestSuccess />}
        />
      </Route>
      <Route
        element={
          <ProtectedRoute
            user={user}
            redirectPath={cancellationRedirectPath()}
          />
        }>
        <Route
          path={RoutePaths.CancellationRequest}
          element={<CancellationRequest locale={locale} />}
        />
      </Route>
      {/* Currently unused routes */}
      <Route
        path={RoutePaths.FeedbackPersonalize}
        element={<FeedbackPersonalize />}
      />
      {/* For any other route, redirect to root path */}
      <Route path="*" element={<Navigate to={RoutePaths.Root} />} />
    </Routes>
  )
}
