import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useLocalStorageState from 'use-local-storage-state'
import { ANSWERS } from 'pages/AgingFunnel/v2/AgingFunnelPersonalityTest'
import useSnowplow from 'hooks/useSnowplow'
import { ContinueButton } from 'components/ContinueButton'
import { Heading } from 'components/Heading'
import { Confetti } from './Confetti'

interface Props {
  nextRoute: string
}

export const AgingFunnelFeedbackPersonalityTest: React.FC<Props> = ({
  nextRoute,
}) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const navigate = useNavigate()
  const [answers] = useLocalStorageState<number[]>(ANSWERS)

  const onContinue = () => {
    snowplowTrack({
      eventName: 'navigation_click',
      property: 'feedback_personalitytest',
    })
    navigate(nextRoute)
  }

  const extroversionCopy = () => {
    const extroversionAnswer = answers?.[0]
    return extroversionAnswer! > 2
      ? t('agingFunnel.v2.feedback_personality_test.extroversion')
      : t('agingFunnel.v2.feedback_personality_test.no_extroversion')
  }

  const conscientiousnessCopy = () => {
    const conscientiousnessAnswer = answers?.[1]
    return conscientiousnessAnswer! > 2
      ? t('agingFunnel.v2.feedback_personality_test.conscientiousness')
      : t('agingFunnel.v2.feedback_personality_test.no_conscientiousness')
  }

  const opennessCopy = () => {
    const opennessAnswer = answers?.[2]
    return opennessAnswer! > 2
      ? t('agingFunnel.v2.feedback_personality_test.openness')
      : t('agingFunnel.v2.feedback_personality_test.no_openness')
  }

  const neuroticismCopy = () => {
    const neuroticismAnswer = answers?.[3]
    return neuroticismAnswer! > 2
      ? t('agingFunnel.v2.feedback_personality_test.neuroticism')
      : t('agingFunnel.v2.feedback_personality_test.no_neuroticism')
  }

  const agreeablenessCopy = () => {
    const agreeablenessAnswer = answers?.[4]
    return agreeablenessAnswer! > 2
      ? t('agingFunnel.v2.feedback_personality_test.agreeableness')
      : t('agingFunnel.v2.feedback_personality_test.no_agreeableness')
  }

  return (
    <>
      <div className="container mx-auto px-4 text-center">
        <div className="my-20">
          <div className="fixed left-1/2">
            <Confetti active />
          </div>
          <Heading
            textColor="ar-green"
            text={t('agingFunnel.v2.feedback_personality_test.headline')}
          />

          <p className="my-4 font-body text-sm text-ar-dark-gray xl:text-center">
            {extroversionCopy()}
          </p>
          <p className="my-4 font-body text-sm text-ar-dark-gray xl:text-center">
            {conscientiousnessCopy()}
          </p>
          <p className="my-4 font-body text-sm text-ar-dark-gray xl:text-center">
            {opennessCopy()}
          </p>
          <p className="my-4 font-body text-sm text-ar-dark-gray xl:text-center">
            {neuroticismCopy()}
          </p>
          <p className="my-4 font-body text-sm text-ar-dark-gray xl:text-center">
            {agreeablenessCopy()}
          </p>
        </div>
      </div>
      <ContinueButton onClick={onContinue} />
    </>
  )
}
