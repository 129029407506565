import { useState, useCallback, useRef, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import useApi from 'hooks/useApi'
import { useUser } from 'context/UserContext'
import { ReactComponent as Logo } from 'icons/logo.svg'
import giftCardImage01 from './gift_card_01.jpg'
import giftCardImage02 from './gift_card_02.jpg'
import giftCardImage03 from './gift_card_03.jpg'
import { ReactComponent as ChevronLeft } from './chevron_left.svg'
import { ReactComponent as ChevronRight } from './chevron_right.svg'
import { ReactComponent as GiftCardOrder } from './gift_card_order.svg'
import { ReactComponent as PersonalNote } from './personal_note.svg'
import { ReactComponent as SendEmail } from './send_email.svg'
import getSymbolFromCurrency from 'currency-symbol-map'
import { RoutePaths } from 'route-paths'

function formatPrice(price: number | string, currency: string = 'USD') {
  let formattedPrice

  if (currency === 'EUR') {
    formattedPrice = `EUR ${price}€`
  } else {
    formattedPrice = `${currency} ${getSymbolFromCurrency(currency)}${price}`
  }

  return formattedPrice
}

const IMAGES = [giftCardImage01, giftCardImage02, giftCardImage03]
const PERSONAL_MESSAGE_LIMIT = 255

interface GiftCardPriceProps {
  unitAmount: number
  currency: string
}

interface Props {
  locale: string
  nextRoute: string
}

export const GiftCard: React.FC<Props> = ({ locale, nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { user, setUser } = useUser()

  const rawPrices = process.env.REACT_APP_RECURLY_GIFT_CARD_PRICES as string
  const parsedPrices = JSON.parse(rawPrices) as GiftCardPriceProps[]

  const [currency] = useState<string>(user.currency || 'USD')

  const amount = useMemo(
    () => parsedPrices.find((c) => c.currency === currency)?.unitAmount,
    [currency, parsedPrices],
  )

  const navigate = useNavigate()
  const { createGiftCard } = useApi(locale)

  const [selectedImage, setSelectedImage] = useState(0)
  const [loading, setLoading] = useState(false)
  const [personalMessage, setPersonalMessage] = useState('')

  const formRef = useRef<null | HTMLFormElement>(null)
  const recipientEmailAddressRef = useRef<null | HTMLInputElement>(null)
  const recipientFirstNameRef = useRef<null | HTMLInputElement>(null)
  const recipientLastNameRef = useRef<null | HTMLInputElement>(null)
  const senderNameRef = useRef<null | HTMLInputElement>(null)
  const personalMessageRef = useRef<null | HTMLTextAreaElement>(null)

  const nextImage = () => {
    let newIndex = (selectedImage + 1) % IMAGES.length
    setSelectedImage(newIndex)
  }

  const prevImage = () => {
    if (selectedImage === 0) {
      setSelectedImage(IMAGES.length - 1)
    } else {
      let newIndex = (selectedImage - 1) % IMAGES.length
      setSelectedImage(newIndex)
    }
  }

  const formValid = formRef?.current?.checkValidity()

  const createBackendGiftCard = useCallback(async () => {
    const authToken = user.authToken
    const productCode = process.env.REACT_APP_RECURLY_GIFT_CARD_CODE!
    const recipientEmailAddress = recipientEmailAddressRef.current?.value

    if (!authToken || !amount || !recipientEmailAddress) {
      return
    }

    if (formRef.current && !formValid) {
      formRef.current.reportValidity()

      return
    }

    setLoading(true)

    try {
      const { recipient_email_address, errors } = await createGiftCard({
        authToken,
        productCode,
        unitAmountInCents: amount,
        recipientEmailAddress,
        recipientFirstName: recipientFirstNameRef?.current?.value,
        recipientLastName: recipientLastNameRef?.current?.value,
        gifterName: senderNameRef?.current?.value,
        personalMessage: personalMessageRef?.current?.value,
      })

      if (errors) {
        setLoading(false)
        toast.error(t('errors.unexpected_error'))
      } else {
        setLoading(false)
        setUser({ ...user, recipientEmailAddress: recipient_email_address })

        navigate(nextRoute)
      }
    } catch (e) {
      setLoading(false)
      toast.error(t('errors.unexpected_error'))
    }
  }, [user, createGiftCard, navigate, setUser, amount, t, nextRoute, formValid])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  if (!amount) {
    return null
  }

  return (
    <>
      <header className="mx-auto grid max-w-[1120px] grid-cols-2 px-4 pt-9">
        <Logo height="50" width="44" fill="black" />
      </header>

      <section
        id="gift-card"
        className="mx-auto mt-10 max-w-[1120px] bg-white px-4">
        <div className="flex h-1 justify-between">
          <div className="w-full bg-ar-green">
            <div className="flex justify-between">
              <div>
                <div className="-mt-1 h-3 w-3 rounded-full bg-ar-green" />
                <p className="mt-4 max-w-[80px] text-xs uppercase text-ar-green sm:max-w-full">
                  {t('gift_card.navigation_step_1')}
                </p>
              </div>

              <div className="flex flex-col">
                <div className="relative flex flex-col">
                  <div className="-mt-1 h-3 w-3 self-center rounded-full bg-ar-green" />
                  <div className="mt-1 w-1 self-center border-x-4 border-b-4 border-t-0 border-solid border-ar-green border-x-transparent"></div>
                </div>
                <p className="absolute left-1/2 mt-6 -translate-x-1/2 transform text-xs uppercase text-ar-green">
                  {t('gift_card.navigation_step_2')}
                </p>
              </div>
            </div>
          </div>

          <div className="flex w-full flex-col bg-ar-green opacity-30">
            <div className="flex flex-col self-end">
              <div className="-mt-1 h-3 w-3 self-end rounded-full bg-ar-green" />
              <p className="mt-4 max-w-[80px] text-right text-xs uppercase text-ar-green sm:max-w-full">
                {t('gift_card.navigation_step_3')}
              </p>
            </div>
          </div>
        </div>

        <div className="mt-20 rounded-2xl border-2 border-gray-100">
          <div className="grid grid-cols-1 justify-between p-4 sm:p-8 lg:grid-cols-2">
            <div className="flex w-full flex-col sm:pr-4">
              <p className="self-start rounded-3xl bg-ar-light-gray p-2 font-link text-xs text-white">
                {t('gift_card.label')}
              </p>

              <p className="mt-6 font-link text-3xl text-ar-dark-gray">
                {t('gift_card.headline_1') + ' - '}
                <span className="text-ar-green">
                  {t('gift_card.headline_2')}
                </span>
              </p>

              <p className="mt-5 font-body text-sm text-ar-dark-gray">
                {t('gift_card.description')}
              </p>

              <p className="mt-10 font-link text-5xl text-ar-dark-gray md:mt-20">
                {formatPrice((amount / 100).toFixed(2), currency)}
              </p>

              <p className="mt-3 font-body text-xs text-ar-light-gray">
                {t('gift_card.payment_info')}
              </p>

              <a
                href="#form"
                data-tracking-event-type="navigationClick"
                data-tracking-element-type="addToOrder"
                onClick={undefined}
                className="text-md mx-auto mt-6 block w-full rounded-lg bg-ar-green py-3 text-center font-link text-white focus:outline-none">
                {t('gift_card.add_to_order')}
              </a>

              <Link
                to={RoutePaths.ThankYou}
                data-tracking-event-type="navigationClick"
                data-tracking-element-type="notInterestedTop"
                className="text-md mt-5 text-center font-link text-ar-dark-gray hover:cursor-pointer">
                {t('gift_card.not_interested')}
              </Link>
            </div>

            <div className="relative mx-auto mt-10 max-w-[512px] lg:mt-0">
              <div
                className="before:content-[' '] after:content-[' '] absolute top-1/2 z-10
                           h-[60px] w-[40px] -translate-y-1/2
                           transform rounded-r-full bg-white before:absolute before:-top-[25px] before:inline-block before:h-[25.5px]
                           before:w-[33px] before:rounded-[50%] before:bg-transparent
                           before:shadow-corner-bl after:absolute after:-bottom-[25px] after:inline-block after:h-[25.5px] after:w-[33px] after:rounded-[50%] after:bg-transparent after:shadow-corner-tl hover:cursor-pointer"
                onClick={prevImage}>
                <ChevronLeft className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-ar-dark-gray hover:cursor-pointer" />
              </div>
              <div
                className="before:content-[' '] after:content-[' '] absolute right-0 top-1/2
                           z-10 h-[60px] w-[40px] -translate-y-1/2
                           transform rounded-l-full bg-white before:absolute before:-top-[25px] before:right-0 before:inline-block
                           before:h-[25.5px] before:w-[33px] before:rounded-[50%] before:bg-transparent
                           before:shadow-corner-br after:absolute after:-bottom-[25px] after:right-0 after:inline-block after:h-[25.5px] after:w-[33px] after:rounded-[50%] after:bg-transparent after:shadow-corner-tr hover:cursor-pointer"
                onClick={nextImage}>
                <ChevronRight className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-ar-dark-gray hover:cursor-pointer" />
              </div>
              <img
                src={IMAGES[selectedImage]}
                className="relative select-none rounded-xl"
                alt="Gift Card with Asana Rebel logo"
              />
              <div className="absolute bottom-5 flex w-full flex-row gap-5 px-5">
                {[...Array(IMAGES.length)].map((_element, index) => {
                  return (
                    <div
                      className={`h-2 w-full cursor-pointer rounded-xl ${
                        index === selectedImage ? 'bg-ar-green' : 'bg-white'
                      }`}
                      key={index}
                      onClick={() => setSelectedImage(index)}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="how" className="mt-14 w-full bg-gray-100 p-14">
        <p className="text-center font-link text-4xl text-black">
          {t('gift_card.how_headline')}
        </p>

        <div className="mt-14 flex flex-col items-center justify-center gap-6 sm:flex-row sm:gap-16">
          <div className="flex w-28 flex-col items-center">
            <GiftCardOrder />
            <p className="mt-5 text-center font-body">
              {t('gift_card.how_step_1')}
            </p>
          </div>

          <ChevronRight className="rotate-90 text-ar-green sm:mb-16 sm:rotate-0" />

          <div className="flex w-28 flex-col items-center">
            <PersonalNote />
            <p className="mt-5 text-center font-body">
              {t('gift_card.how_step_2')}
            </p>
          </div>

          <ChevronRight className="rotate-90 text-ar-green sm:mb-16 sm:rotate-0" />

          <div className="flex w-28 flex-col items-center">
            <SendEmail />
            <p className="mt-5 text-center font-body">
              {t('gift_card.how_step_3')}
            </p>
          </div>
        </div>
      </section>

      <section id="form" className="mx-auto mt-12 max-w-[1120px] bg-white px-4">
        <div className="rounded-2xl border-2 border-gray-100 p-4 sm:p-8">
          <form ref={formRef}>
            <h1 className="font-link text-3xl">
              {t('gift_card.form_headline')}
            </h1>
            <p className="mt-4 font-body">{t('gift_card.form_description')}</p>

            <div className="mt-16 grid grid-cols-1 gap-x-16 gap-y-8 sm:grid-cols-2">
              <div className="flex flex-col gap-2">
                <label
                  htmlFor="recipient_first_name"
                  className="font-link text-sm uppercase text-ar-dark-gray">
                  {t('gift_card.recipient_first_name')}
                </label>
                <input
                  ref={recipientFirstNameRef}
                  type="text"
                  id="recipient_first_name"
                  className="rounded-lg border-2 border-gray-300 p-4 font-body text-sm focus:outline-none"
                />
              </div>

              <div className="flex flex-col gap-2">
                <label
                  htmlFor="recipient_last_name"
                  className="font-link text-sm uppercase text-ar-dark-gray">
                  {t('gift_card.recipient_last_name')}
                </label>
                <input
                  ref={recipientLastNameRef}
                  type="text"
                  id="recipient_last_name"
                  className="rounded-lg border-2 border-gray-300 p-4 font-body text-sm focus:outline-none"
                />
              </div>

              <div className="flex flex-col gap-2">
                <label
                  htmlFor="recipient_email"
                  className="font-link text-sm uppercase text-ar-dark-gray">
                  {t('gift_card.recipient_email')}
                  <span className="text-red-500"> *</span>
                </label>
                <input
                  ref={recipientEmailAddressRef}
                  required
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  type="email"
                  id="recipient_email"
                  className="rounded-lg border-2 border-gray-300 p-4 font-body text-sm focus:outline-none"
                />
              </div>

              <div className="flex flex-col gap-2 sm:col-span-2">
                <label
                  htmlFor="personal_message"
                  className="font-link text-sm uppercase text-ar-dark-gray">
                  {t('gift_card.personal_message')}
                </label>
                <textarea
                  ref={personalMessageRef}
                  value={personalMessage}
                  onChange={(event) =>
                    setPersonalMessage(
                      event.target.value.slice(0, PERSONAL_MESSAGE_LIMIT),
                    )
                  }
                  id="personal_message"
                  rows={4}
                  className="rounded-lg border-2 border-gray-300 p-4 font-body text-sm focus:outline-none"
                />
                <small className="font-body text-xs text-ar-dark-gray">
                  {t('gift_card.personal_message_limit', {
                    count: PERSONAL_MESSAGE_LIMIT - personalMessage.length,
                  })}
                </small>
              </div>

              <div className="flex flex-col gap-2">
                <label
                  htmlFor="sender_name"
                  className="font-link text-sm uppercase text-ar-dark-gray">
                  {t('gift_card.sender_name')}
                </label>
                <input
                  ref={senderNameRef}
                  type="text"
                  id="sender_name"
                  className="rounded-lg border-2 border-gray-300 p-4 font-body text-sm focus:outline-none"
                />
              </div>
            </div>
          </form>
        </div>

        <div className="mx-auto mt-16 mb-16 grid w-full max-w-[1120px] grid-cols-1 justify-center gap-2 px-4 text-center sm:w-1/3">
          <h1 className="font-link text-3xl">
            {t('gift_card.total')}
            {' ' + formatPrice((amount / 100).toFixed(2), currency)}
          </h1>
          <small className="font-body text-xs text-ar-light-gray">
            {t('gift_card.payment_info')}
          </small>
          <button
            data-tracking-event-type="navigationClick"
            data-tracking-element-type="purchaseGiftCard"
            type="button"
            disabled={loading || !formValid}
            onClick={createBackendGiftCard}
            className={`text-md mx-auto mt-6 block w-full rounded-lg bg-ar-green py-3 text-center font-link text-white focus:outline-none ${
              loading || !formValid ? 'bg-opacity-50' : ''
            }`}>
            {t('gift_card.purchase')}
          </button>

          <Link
            to={RoutePaths.ThankYou}
            data-tracking-event-type="navigationClick"
            data-tracking-element-type="notInterestedBottom"
            className="text-md mt-5 text-center font-link text-ar-dark-gray">
            {t('gift_card.not_interested')}
          </Link>
        </div>
      </section>
      <ToastContainer position="bottom-center" hideProgressBar />
    </>
  )
}
