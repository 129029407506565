import { useTranslation } from 'react-i18next'
import usePricing from 'hooks/usePricing'
import { CouponResponse } from 'hooks/useApi'
import { Plan } from 'pages/Plans'

interface Props {
  currency: string
  plan: Plan
  coupon?: CouponResponse | null
}

export const OrderSummary: React.FC<Props> = ({ currency, plan, coupon }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { trial, totalPrice, totalDiscountedPrice } = usePricing(
    plan,
    currency,
    coupon,
  )

  const fullPrice = () => {
    if (!totalPrice) {
      return null
    }

    return (
      <span className={`${totalDiscountedPrice !== '' ? 'line-through' : ''}`}>
        {totalPrice}
      </span>
    )
  }

  const translatedTrialUnit = () => {
    if (!trial) {
      return
    }

    return t(`checkout.trial.${trial.unit}`, { count: trial.amount })
  }

  const priceWithDiscount = () => {
    if (totalDiscountedPrice === '') {
      return null
    }

    return (
      <span
        className={`${
          totalDiscountedPrice !== '' ? 'inline-block' : 'hidden'
        }`}>
        {totalDiscountedPrice}
      </span>
    )
  }

  return (
    <p className="text-md font-title font-bold text-ar-green">
      {!trial && <>{t('checkout.amount_due')} </>}
      {trial && (
        <>
          <span>
            {translatedTrialUnit()} {t('checkout.trial_summary')}
          </span>
        </>
      )}
      {fullPrice()} {priceWithDiscount()}
    </p>
  )
}
