import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUser } from 'context/UserContext'
import useSnowplow from 'hooks/useSnowplow'
import { ChoiceButton } from 'components/ChoiceButton'
import { ButtonWrapper } from 'components/ButtonWrapper'
import { Heading } from 'components/Heading'
import { PreLoadImage } from 'components/PreLoadImage'

interface Props {
  nextRoute: string
}

export const FitnessLevel: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const navigate = useNavigate()
  const { user, setUser } = useUser()
  const genderBasedBackground =
    user.gender === 'm' ? 'bg-fitness-level-male' : 'bg-fitness-level-female'
  const preloadBackgrounds =
    user.gender === 'm'
      ? ['bg-age-male-mobile', 'md:bg-age-male']
      : ['bg-age-female']

  const onButtonClick = (fitness: string) => {
    snowplowTrack({
      eventName: 'navigation_click',
      property: 'fitness_level',
      label: fitness,
    })

    setUser({ ...user, fitness })
    navigate(nextRoute)
  }

  return (
    <div
      className={`z-[-10] h-screen bg-ar-dark-gray bg-cover bg-center bg-no-repeat ${genderBasedBackground}`}>
      {preloadBackgrounds.map((bg) => (
        <PreLoadImage key={bg} background={bg} />
      ))}
      <div className="mx-auto flex flex-col justify-between px-8 pt-9 lg:w-5/6 xl:w-5/12">
        <Heading text={t('fitness_level.headline')} />

        <ButtonWrapper>
          <ChoiceButton
            onClick={() => onButtonClick('beginner')}
            value={t('fitness_level.fitness_level_1')}
            id="beginner"
          />
          <ChoiceButton
            onClick={() => onButtonClick('intermediate')}
            value={t('fitness_level.fitness_level_2')}
            id="intermediate"
          />
          <ChoiceButton
            onClick={() => onButtonClick('advanced')}
            value={t('fitness_level.fitness_level_3')}
            id="advanced"
          />
        </ButtonWrapper>
      </div>
    </div>
  )
}
