import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUser, GoalType } from 'context/UserContext'
import useSnowplow from 'hooks/useSnowplow'
import { Heading } from 'components/Heading'
import { ButtonWrapper } from 'components/ButtonWrapper'
import { ChoiceButton } from 'components/ChoiceButton'
import { RoutePaths } from 'route-paths'

interface Props {
  nextRoute: string
}

export const GoalSelection: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const { user, setUser } = useUser()
  const genderBasedBackground =
    user.gender === 'm'
      ? 'bg-goals-selection-male'
      : 'bg-goals-selection-female bg-center'
  const navigate = useNavigate()

  useEffect(() => {
    if (!user.goalTypes) {
      navigate(RoutePaths.Goals, { replace: true })
    }
  }, [user.goalTypes, navigate])

  const onButtonClick = (goal: GoalType) => {
    snowplowTrack({
      eventName: 'navigation_click',
      property: 'goal_selection',
      label: goal.id,
    })

    setUser({ ...user, firstGoal: goal })
    navigate(nextRoute)
  }

  if (!user.goalTypes) {
    return null
  }

  return (
    <>
      <div
        className={`z-[-10] h-screen bg-ar-dark-gray bg-cover bg-no-repeat ${genderBasedBackground}`}>
        <div className="mx-auto flex flex-col justify-between px-8 pt-9 lg:w-5/6 xl:w-5/12">
          <Heading text={t('goal_selection.headline')} />

          <ButtonWrapper>
            {user.goalTypes.map((goal) => (
              <ChoiceButton
                onClick={() => onButtonClick(goal)}
                value={goal.display}
                id={goal.id}
                key={goal.key}
              />
            ))}
          </ButtonWrapper>
        </div>
      </div>
    </>
  )
}
