import { useTranslation } from 'react-i18next'
import usePricing from 'hooks/usePricing'
import useSnowplow from 'hooks/useSnowplow'
import { Modal } from '.'
import { Plan } from 'pages/Plans'
import { Heading } from 'components/Heading'

interface Props {
  plan: Plan
  currency: string
  onClick: () => void
  onClose: () => void
  open: boolean
}

export const SpecialOfferModal: React.FC<Props> = ({
  plan,
  currency,
  onClick,
  onClose,
  open,
}) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const { totalPrice, campaignTotalPrice } = usePricing(plan, currency)

  return (
    <Modal
      onClose={() => {
        snowplowTrack({
          eventName: 'navigation_click',
          property: 'special_offer_modal_close',
        })
        onClose()
      }}
      open={open}>
      <div className="flex flex-col gap-3 px-8 py-10 text-center">
        <Heading
          text={t('plans.headline_modal').toUpperCase()}
          centered
          textColor="ar-dark-gray"
        />
        <p className="text-sm">
          {t('plans.months', { count: plan.intervalLength })}
        </p>
        <div className="text-md text-ar-green line-through">
          {campaignTotalPrice}
        </div>
        <div className="text-md font-link text-ar-dark-gray">{totalPrice}</div>
        <button
          id="add_to_cart_modal"
          type="button"
          onClick={() => {
            snowplowTrack({
              eventName: 'navigation_click',
              property: 'special_offer_modal_button',
            })
            onClick()
          }}
          className="text-md block w-full rounded-lg bg-ar-green p-4 text-center font-link text-white focus:outline-none">
          {t('plans.call_to_action_alt')}
        </button>

        <p className="font-title text-sm text-black">{t('plans.money_back')}</p>
      </div>
    </Modal>
  )
}
