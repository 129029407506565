import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUser, GoalType } from 'context/UserContext'
import useSnowplow from 'hooks/useSnowplow'
import { ContinueButton } from 'components/ContinueButton'
import { GoalButton } from 'components/GoalButton'
import { Heading } from 'components/Heading'
import { PreLoadImage } from 'components/PreLoadImage'

interface Props {
  nextRoute: string
}

export const AgingFunnelSymptoms: React.FC<Props> = ({ nextRoute }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  })

  const { snowplowTrack } = useSnowplow()
  const { user, setUser } = useUser()
  const genderBasedBackground =
    user.gender === 'm'
      ? 'bg-goals-male bg-right'
      : 'bg-goals-female bg-top-left'
  const preloadBackground =
    user.gender === 'm'
      ? 'bg-goals-selection-male'
      : 'bg-goals-selection-female'
  const [canContinue, setCanContinue] = useState(false)
  const [symptoms, setSymptoms] = useState([
    {
      key: 1,
      display: t('agingFunnel.symptoms.symptom_1'),
      value: 'joint-pain',
      position: '-left-8 top-14',
      selected: false,
      id: 'joint_pain',
    },
    {
      key: 2,
      display: t('agingFunnel.symptoms.symptom_2'),
      value: 'stiffness',
      position: 'left-24',
      selected: false,
      id: 'stiffness',
    },
    {
      key: 3,
      display: t('agingFunnel.symptoms.symptom_3'),
      value: 'lower-energy',
      position: '-right-8 top-16',
      selected: false,
      id: 'lower_energy',
    },
    {
      key: 4,
      display: t('agingFunnel.symptoms.symptom_4'),
      value: 'lower-muscle-mass',
      position: 'left-24 top-36',
      selected: false,
      id: 'lower_muscle_mass',
    },
    {
      key: 5,
      display: t('agingFunnel.symptoms.symptom_5'),
      value: 'dry-skin',
      position: '-left-8 top-52',
      selected: false,
      id: 'dry_skin',
    },
    {
      key: 6,
      display: t('agingFunnel.symptoms.symptom_6'),
      value: 'slower-learning',
      position: '-right-3 top-60',
      selected: false,
      id: 'slower_learning',
    },
  ])
  const navigate = useNavigate()

  useEffect(() => {
    setCanContinue(symptoms.filter(({ selected }) => selected).length > 0)
  }, [symptoms])

  const onGoalClick = (symptom: GoalType) => {
    let symptomsCopy = [...symptoms]
    symptomsCopy[symptomsCopy.findIndex((el) => el.key === symptom.key)] = {
      ...symptom,
      selected: !symptom.selected,
    }
    setSymptoms(symptomsCopy)
  }

  const onContinue = () => {
    const selectedSymptoms = symptoms.filter(({ selected }) => selected)

    snowplowTrack({
      eventName: 'navigation_click',
      property: 'symptoms',
      label: selectedSymptoms.map(({ id }) => id).join(','),
    })

    setUser({
      ...user,
      symptoms: selectedSymptoms.map(({ value }) => value),
      goalTypes: selectedSymptoms,
    })

    navigate(nextRoute)
  }

  return (
    <>
      <PreLoadImage background={preloadBackground} />
      <div
        className={`z-[-10] h-screen bg-ar-dark-gray bg-cover bg-no-repeat ${genderBasedBackground}`}>
        <div className="mx-auto flex flex-col justify-between px-8 pt-9 lg:w-5/6 xl:w-5/12">
          <div>
            <Heading text={t('agingFunnel.symptoms.headline')} />
          </div>

          <div className="relative mt-5 sm:flex sm:flex-col sm:space-y-4">
            {symptoms.map((symptom) => (
              <GoalButton
                key={symptom.key}
                value={symptom.display}
                position={symptom.position}
                selected={symptom.selected}
                onClick={() => onGoalClick(symptom)}
                id={symptom.id}
              />
            ))}
          </div>
        </div>
        <ContinueButton onClick={onContinue} disabled={!canContinue} />
      </div>
    </>
  )
}
